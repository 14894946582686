import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {Button, Toolbar} from "devextreme-react";
import React, {useEffect, useState} from "react";
import {TechnicianOrder} from "../../../domain/TechnicianOrder";
import {TechnicianOrderStore} from "../../../datastore/TechnicianOrderStore";
import {Item as ToolbarItem} from "devextreme-react/toolbar";
import Form, {GroupItem, SimpleItem} from "devextreme-react/form";
import {Column as GridColumn, DataGrid} from "devextreme-react/data-grid";
import {alert, confirm} from "devextreme/ui/dialog";
import {hideLoadPanel, showLoadPanel} from "../../../utils/utils";

export function TechnicianOrdersEdit() {
    const navigate = useNavigate();
    const [record, setRecord] = useState<TechnicianOrder>();
    const {id} = useParams();

    useEffect(() => {
        TechnicianOrderStore.byKey(Number(id)).then((data) => {
            setRecord(data);
        });
    }, [id]);

    const goBack = () => {
        navigate(`/technician-orders`);
    }

    const editClick = () => {
        navigate(`/technician-orders/${id}`);
    }
    const cancelClick = () => {
        confirm("Teknisyen siparişini iptal etmek istiyor musunuz?", "Teknisyen Siparişi İptal")
            .then((dialogResult) => {
                if (dialogResult) {
                    TechnicianOrderStore.cancel(Number(id))
                        .then(() => {
                            alert("Teknisyen siparişi iptal edildi.", "Teknisyen Siparişi İptal")
                                .then(() => {
                                    goBack();
                                })
                        });
                }
            });
    }

    const completeClick = () => {
        confirm("Teknisyen siparişini tamamlamak istiyor musunuz?", "Teknisyen Siparişini Tamamla")
            .then((dialogResult) => {
                if (dialogResult) {
                    showLoadPanel();
                    TechnicianOrderStore.complete(Number(id))
                        .then(() => {
                            alert("Teknisyen siparişi tamamlandı, tüm ürünler araç deposuna eklendi.", "Teknisyen Siparişi Tamamla")
                                .then(() => {
                                    goBack();
                                })
                        })
                        .finally(() => {
                            hideLoadPanel();
                        });
                }
            });
    }
    const waitingClick = () => {
        confirm("Siparişi beklemeye almak istiyor musunuz?", "Teknisyen Siparişini Beklemeye Al")
            .then((dialogResult) => {
                if (dialogResult) {
                    TechnicianOrderStore.waiting(Number(id))
                        .then(() => {
                            alert("Sipariş beklemeye alındı.", "Siparişi Beklemeye Al")
                                .then(() => {
                                    goBack();
                                })
                        });
                }
            });
    }

    return (
        <>
            <Toolbar>
                <ToolbarItem location="before">
                    <Button text="SİPARİŞ LİSTESİ" onClick={goBack} icon="undo"/>
                </ToolbarItem>
                <ToolbarItem location="before">
                    <Button text="SİPARİŞ GÜNCELLE" onClick={editClick} icon="edit"/>
                </ToolbarItem>
                <ToolbarItem location="before">
                    <Button text="SİPARİŞİ TAMAMLA" onClick={completeClick} icon="check"
                            disabled={!(record?.state === "WAITING")}/>
                </ToolbarItem>
                <ToolbarItem location="before">
                    <Button text="SİPARİŞİ BEKLEMEYE AL" onClick={waitingClick} icon="redo"
                            disabled={!(record?.state !== "WAITING")}/>
                </ToolbarItem>
                <ToolbarItem location="before">
                    <Button text="SİPARİŞİ İPTAL ET" onClick={cancelClick} icon="clear"
                            disabled={!(record?.state !== "CANCELLED")}/>
                </ToolbarItem>
            </Toolbar>


            <Form formData={record} readOnly={true}
            >
                <GroupItem colCount={3}>
                    <SimpleItem dataField="technician.name" editorType="dxTextBox" label={{text: "Teknisyen"}}
                                editorOptions={{readOnly: true}}/>

                    <SimpleItem dataField="createdAt" editorType="dxDateBox"
                                label={{text: "Oluşturulma Tarihi & Saati"}}
                                editorOptions={
                                    {
                                        dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
                                        type: 'datetime',
                                        readOnly: true
                                    }
                                }/>

                    <SimpleItem dataField="completedAt" editorType="dxDateBox"
                                label={{text: "Tamamlanma Tarihi & Saati"}}
                                editorOptions={
                                    {
                                        dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
                                        type: 'datetime',
                                        readOnly: true
                                    }
                                }/>
                </GroupItem>

                <GroupItem caption="Ürünler">
                    <DataGrid
                        dataSource={record?.products}
                        paging={{enabled: false}}
                        showBorders={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                    >
                        <GridColumn dataField="product.brand.title" caption="Marka Adı"/>
                        <GridColumn dataField="product.title" caption="Ürün Adı"/>
                        <GridColumn dataField="quantity" caption="Miktar" format="#0.##" width="80"/>
                    </DataGrid>
                </GroupItem>
            </Form>
        </>
    )
}