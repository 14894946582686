import axios from "axios";
import notify from "devextreme/ui/notify";
import {createContext, useContext} from "react";

axios.defaults.baseURL = "";
axios.defaults.headers.Accept = 'application/json';

const isoDateTimeFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})([.]\d*)?$/;
const isoDateFormat = /^(\d{4})-(\d{2})-(\d{2})$/;
const isoUtcDateTimeFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})([.]\d*)?Z$/;

function isIsoDateTimeString(value) {
    return value && typeof value === "string" && isoDateTimeFormat.test(value);
}

function isIsoDateString(value) {
    return value && typeof value === "string" && isoDateFormat.test(value);
}

function isIsoUtcDateTimeString(value) {
    return value && typeof value === "string" && isoUtcDateTimeFormat.test(value);
}

function handleDates(body) {
    if (body === null || body === undefined || typeof body !== "object")
        return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateTimeString(value)) body[key] = new Date(value);
        else if (isIsoDateString(value)) body[key] = new Date(value);
        else if (isIsoUtcDateTimeString(value)) body[key] = new Date(value);
        else if (typeof value === "object") handleDates(value);
    }
}

function notifyMessage(msg) {
    notify({
        message: msg,
        height: 45,
        type: "error",
        displayTime: 2000,
        animation: {
            show: {
                type: 'fade', duration: 400, from: 0, to: 1,
            },
            hide: {type: 'fade', duration: 40, to: 0},
        },
    }, {position: 'top right', direction: 'down-stack'});
}

function AxiosProvider(props) {
    axios.interceptors.response.use(
        (response) => {
            handleDates(response.data);
            return response;
        }, (err) => {
            if (err.response.status === 401) {
                notifyMessage("Bu işlemi yapmak için yetkiniz bulunmuyor.");
            } else if (err.response && err.response.data) {
                let apiException = err.response.data;
                notifyMessage(apiException.detail);
            }
            return Promise.reject(err);
        }
    );
    return (
        <AxiosContext.Provider value={{}} {...props} />
    )
}

const AxiosContext = createContext({});

const useAxiosProvider = () => useContext(AxiosContext);

export {AxiosProvider, useAxiosProvider};


export const axiosTokenConfig = (authToken) => {
    axios.interceptors.request.clear();
    axios.interceptors.request.use(
        (config) => {
            if (config.url === "/api/auth/login") {
                config.headers.Authorization = null;
            } else if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`
            }
            return config;
        },
        (err) => Promise.reject(err)
    );
}