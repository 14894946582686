import Form, {RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import {useEffect, useRef, useState} from "react";
import {TransportType} from "../../../domain/TransportType";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {TransportTypeStore} from "../../../datastore/TransportTypeStore";
import {isFormValid} from "../../../utils/utils";
import {confirm} from 'devextreme/ui/dialog';

export function TransportTypesEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const [record, setRecord] = useState<TransportType>();
    const {id} = useParams();
    const isNewRecord = !id;

    useEffect(() => {
        if (!isNewRecord) {
            TransportTypeStore.byKey(Number(id)).then((data) => {
                setRecord(data);
            });
        } else {
            setRecord({});
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/brands")
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (isNewRecord) {
            TransportTypeStore.insert(record!).then(cancelClick);
        } else {
            TransportTypeStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`<b>${record?.title}</b> markasını silmek istiyor musunuz?`, "Marka Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    TransportTypeStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni Nakliye Şekli" : "Nakliye Şekli Güncelle"}/>
            <Form ref={formRef}
                  formData={record}
                  onContentReady={(e) => {
                      e.component.getEditor("title")?.focus();
                  }}
            >
                <SimpleItem dataField="title" editorType="dxTextBox" label={{text: "Nakliye Şekli Adı"}}>
                    <RequiredRule message="Lütfen nakliye şekli adı giriniz"/>
                </SimpleItem>
            </Form>
            <Toolbar>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>
        </>
    )
}