import {EnumStore} from "./base/EnumStore";

const Weekdays: any = {
    MONDAY: "Pazartesi",
    TUESDAY: "Salı",
    WEDNESDAY: "Çarşamba",
    THURSDAY: "Perşembe",
    FRIDAY: "Cuma",
    SATURDAY: "Cumartesi",
    SUNDAY: "Pazar"
}

export const WeekdaysStore = new EnumStore({
        key: "id",
        data: [
            {"id": "MONDAY", "title": Weekdays.MONDAY},
            {"id": "TUESDAY", "title": Weekdays.TUESDAY},
            {"id": "WEDNESDAY", "title": Weekdays.WEDNESDAY},
            {"id": "THURSDAY", "title": Weekdays.THURSDAY},
            {"id": "FRIDAY", "title": Weekdays.FRIDAY},
            {"id": "SATURDAY", "title": Weekdays.SATURDAY},
            {"id": "SUNDAY", "title": Weekdays.SUNDAY}
        ]
    }
);
