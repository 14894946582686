import {EnumStore} from "./base/EnumStore";

export const CustomerVendingStateStore = new EnumStore({
        key: "id",
        data: [
            {"id": "AGREEMENT", "title": "Sözleşme"},
            {"id": "INITIALIZING", "title": "Hazırlanıyor"},
            {"id": "TRANSPORTING", "title": "Taşınıyor"},
            {"id": "INSTALLATION_WAITING", "title": "Kurulum Bekliyor"},
            {"id": "OPERATING", "title": "Çalışıyor"},
            {"id": "OUT_OF_ORDER", "title": "Bozuldu"},
            {"id": "RETURN_WAITING", "title": "İade Bekliyor"},
            {"id": "UNLOADING", "title": "Boşaltılıyor"},
            {"id": "TERMINATED", "title": "İade Edildi"},
        ]
    }
);