import {Column,} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {WeekdaysStore} from "../../../datastore/WeekdaysStore";
import {Customer} from "../../../domain/Customer";
import {CustomerStore} from "../../../datastore/CustomerStore";

export function CustomersList() {
    return (
        <BaseDataGridList
            dataSource={CustomerStore}
            header="Müşteriler"
            editHref="/customers"
        >
            <Column dataField="title" caption="Müşteri Adı" width={300} allowResizing={true}/>
            <Column dataField="technician.name" caption="Teknisyen Adı" width={200} allowResizing={true}/>
            <Column dataField="hotDrinkVendingsCount" caption="Sıcak Makine" allowFiltering={false} width={120} allowResizing={true}/>
            <Column dataField="snackVendingsCount" caption="Snack Makine" allowFiltering={false} width={120} allowResizing={true}/>
            <Column dataField="coldDrinkVendingsCount" caption="Soğuk Makine" allowFiltering={false} width={120} allowResizing={true}/>
            <Column dataField="totalVendingsCount" caption="Toplam Makine" allowFiltering={false} width={120} allowResizing={true}/>
            <Column dataField="visits" caption="Ziyaret Günleri"
                    allowSorting={false}
                    allowResizing={true}
                    calculateDisplayValue={(data: Customer) => WeekdaysStore.joinArray(data.visits || [], "title")}
                    calculateCellValue={(data: Customer) => WeekdaysStore.joinArray(data.visits || [], "title")}
                    lookup={
                        {
                            dataSource: WeekdaysStore,
                            displayExpr: 'title',
                            valueExpr: 'id',
                            allowClearing: true
                        }
                    }
            />
            <Column dataField="region.title" caption="Bölgeler" allowResizing={true}/>
        </BaseDataGridList>
    )
}
