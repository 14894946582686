import {BaseStore} from "./base/BaseStore";
import axios, {AxiosResponse} from "axios";
import {TechnicianOrder} from "../domain/TechnicianOrder";
import {formatDate} from "devextreme/localization";

class TechnicianOrderStoreImpl extends BaseStore<TechnicianOrder, number> {
    constructor() {
        super("/api/v1/technician-orders");
    }

    createTechnicianVisitDate(technicianId: number, visitDate: Date): Promise<AxiosResponse<void>> {
        const queryParams = [];
        queryParams.push(`technicianId=${technicianId}`);
        queryParams.push("visitDate=" + formatDate(visitDate, "yyyy-MM-dd"));
        return axios.post(`${this.url}/technician-visit-date?` + queryParams.join('&'));
    }

    waiting(id: number): Promise<AxiosResponse<void>> {
        return axios.post(`${this.url}/${id}/waiting`);
    }

    complete(id: number): Promise<AxiosResponse<void>> {
        return axios.post(`${this.url}/${id}/complete`);
    }

    cancel(id: number): Promise<AxiosResponse<void>> {
        return axios.post(`${this.url}/${id}/cancel`);
    }
}

export const TechnicianOrderStore = new TechnicianOrderStoreImpl();