import {
    Column,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {SupplierStore} from "../../../datastore/SupplierStore";

export function SuppliersList() {
    return (
        <BaseDataGridList
            dataSource={SupplierStore}
            header="Tedarikçiler"
            editHref="/suppliers"
        >
            <Column dataField="title" caption="Tedarikçi Adı"/>
            {/* TODO supplier other fields */}
        </BaseDataGridList>
    )
}
