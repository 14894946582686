import {EnumStore} from "./base/EnumStore";

export const TaskStateStore = new EnumStore({
        key: "id",
        data: [
            {"id": "WAITING", "title": "Bekliyor"},
            {"id": "DONE", "title": "Tamamlandı"},
            {"id": "CANCELLED_BY_OFFICER", "title": "İptal Edildi"}
        ]
    }
);