import {BaseStore} from "./base/BaseStore";
import {TechnicianHandheld} from "../domain/TechnicianHandheld";
import axios, {AxiosResponse} from "axios";

class TechnicianHandheldStoreImpl extends BaseStore<TechnicianHandheld, number> {
    constructor() {
        super("/api/v1/technician-handhelds");
    }

    broken(id: number): Promise<AxiosResponse<void>> {
        return axios.post(`${this.url}/${id}/broken`);
    }

    return(id: number): Promise<AxiosResponse<void>> {
        return axios.post(`${this.url}/${id}/return`);
    }

    given(id: number): Promise<AxiosResponse<void>> {
        return axios.post(`${this.url}/${id}/given`);
    }
}
export const TechnicianHandheldStore = new TechnicianHandheldStoreImpl();