import axios from "axios";
import {AxiosResponse} from "axios";

class StatisticsStoreImpl {
    sales(): Promise<AxiosResponse<StatisticsSales>> {
        return axios.get<StatisticsSales>("/api/v1/statistics/sales")
    }
}

export const StatisticsStore = new StatisticsStoreImpl()

export interface StatisticsSales {
    today: number,
    week: number,
    month: number
}