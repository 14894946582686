import Form, {Item, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {
    CustomerVendingHotDrink,
    CustomerVendingProduct,
    CustomerVendingVersion
} from "../../../domain/CustomerVendingVersion";
import {SelectBox, TabPanel, TextBox} from "devextreme-react";
import {Item as TabPanelItem} from "devextreme-react/tab-panel";
import {
    Column as GridColumn,
    DataGrid,
    Editing as GridEditing,
    Item as GridItem,
    Selection as GridSelection,
    Toolbar as GridToolbar
} from "devextreme-react/data-grid";
import {CustomerVendingVersionStore} from "../../../datastore/CustomerVendingVersionStore";
import {ProductStore} from "../../../datastore/ProductStore";
import {Product} from "../../../domain/Product";
import {OperatingSystem} from "../../../domain/OperatingSystem";
import {OperatingSystemStore} from "../../../datastore/OperatingSystemStore";
import {PaymentDevice} from "../../../domain/PaymentDevice";
import {PaymentDeviceStore} from "../../../datastore/PaymentDeviceStore";
import {isFormValid} from "../../../utils/utils";
import {alert, confirm} from 'devextreme/ui/dialog';
import {VendingImageStore} from "../../../datastore/VendingImageStore";
import {VendingModelHotDrink} from "../../../domain/VendingModel";
import {CustomerVendingStore} from "../../../datastore/CustomerVendingStore";
import {CustomerVending} from "../../../domain/CustomerVending";

function customTitleEditCell(cell: any, hotDrinkOptionNumbersMap: Map<number, VendingModelHotDrink> | undefined) {
    const hotDrinkTitle = hotDrinkOptionNumbersMap?.get(cell.data.optionNumber)?.hotDrink.title;
    return <TextBox
        placeholder={hotDrinkTitle}
        maxLength={40}
        defaultValue={cell.data.customTitle}
        onValueChanged={({value}) => {
            cell.data.customTitle = value;
        }
        }
    />
}

function operatingSystemTitleEditCell(cell: any) {
    return <SelectBox
        dataSource={OperatingSystemStore}
        displayExpr={(p: OperatingSystem) => p?.title || ""}
        searchEnabled={true}
        searchExpr="title"
        defaultValue={cell.data}
        onValueChanged={({value}) => {
            cell.data.id = value.id;
            cell.data.title = value.title;
        }
        }
    />
}

function paymentDeviceChasisCodeEdit(cell: any) {
    return <SelectBox
        dataSource={PaymentDeviceStore}
        displayExpr={(p: PaymentDevice) => p?.chassisCode ?? ""}
        searchEnabled={true}
        searchExpr="title"
        defaultValue={cell.data}
        onValueChanged={({value}) => {
            cell.data.id = value.id;
            cell.data.chassisCode = value.chassisCode;
            cell.data.paymentSystem = value.paymentSystem;
            cell.data.mobile = value.mobile;
        }
        }
    />
}

function productsProductEditCell(cell: any) {
    return <SelectBox
        dataSource={ProductStore}
        displayExpr={(p: Product) => p ? (p.brand?.title + " - " + p.title) : ""}
        searchEnabled={true}
        minSearchLength={1}
        searchExpr="title"
        defaultValue={cell.value}
        onValueChanged={({value}) => {
            cell.setValue(value);
        }}
    />
}

export function CustomerVendingVersionsEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const [record, setRecord] = useState<CustomerVendingVersion>();
    const [hotDrinkOptionNumbersMap, setHotDrinkOptionNumbersMap] = useState<Map<number, VendingModelHotDrink>>();
    const [selectedHotDrinks, setSelectedHotDrinks] = useState<Array<number>>();
    const {customerVendingId, id} = useParams();
    // const [rowsCount, setRowsCount] = useState(0);
    // const [colsCount, setColsCount] = useState(0);
    const isNewRecord = !id;
    const productsGridRef = useRef<DataGrid>(null);
    const hotDrinksGridRef = useRef<DataGrid>(null);
    const operatingSystemsGridRef = useRef<DataGrid>(null);
    const paymentDevicesGridRef = useRef<DataGrid>(null);
    const store = CustomerVendingVersionStore(Number(customerVendingId));
    const [productsVisible, setProductsVisible] = useState(false);
    const [hotDrinksVisible, setHotDrinksVisible] = useState(false);
    const [customerVending, setCustomerVending] = useState<CustomerVending>();
    const [currentState, setCurrentState] = useState("");
    const [readOnly, setReadOnly] = useState(false);
    const panelHeight = 500;

    const loadData = (data: CustomerVendingVersion) => {
        data.products = data.products ?? [];
        data.hotDrinks = data.hotDrinks ?? [];
        data.operatingSystems = data.operatingSystems ?? [];
        data.paymentDevices = data.paymentDevices ?? [];
        setCurrentState(data.state ?? "DRAFT");
        setReadOnly(data.state !== "DRAFT");
        setCustomerVending(data.customerVending);

        const isHotDrink = data.customerVending?.vending?.model?.type === "HOT_DRINK";

        setHotDrinksVisible(isHotDrink);
        setProductsVisible(!isHotDrink);
        if (isHotDrink) {
            // add non-existing hot drinks
            const hotDrinks = new Map<number, CustomerVendingHotDrink>();

            // create a map from using hot drinks
            data.hotDrinks.forEach((h) => hotDrinks.set(h.optionNumber, h));

            const hotDrinkMap = new Map<number, VendingModelHotDrink>();
            data.customerVending?.vending?.model?.hotDrinks?.forEach((h) => {
                // create a map for hot drinks by order number
                hotDrinkMap.set(h.optionNumber, h);

                // add missing order numbers into record hot drinks
                if (!hotDrinks.has(h.optionNumber)) {
                    data.hotDrinks?.push({
                        optionNumber: h.optionNumber,
                        enabled: false
                    });
                }
            });

            setHotDrinkOptionNumbersMap(hotDrinkMap);
            setSelectedHotDrinks(data.hotDrinks.filter((h) => h.enabled).map((h) => h.optionNumber));
        }
        setRecord(data);
    }

    useEffect(() => {
        if (!isNewRecord) {
            CustomerVendingVersionStore(Number(customerVendingId)).byKey(Number(id)).then((resp) => {
                loadData(resp);
            });
        } else {
            // load customer vending
            CustomerVendingStore.getVendingModelWithModel(Number(customerVendingId)).then((resp) => {
                loadData({
                    customerVending: resp.data,
                    state: "DRAFT"
                });
            });
        }
    }, [id, customerVendingId, isNewRecord]);

    const cancelClick = () => {
        navigate(`/customer-vendings/${customerVendingId}/state`);
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (productsGridRef.current?.instance.hasEditData()) {
            alert("Ürün listesinde tamamlanmamış değişiklik bulunmaktadır. Lütfen ilgili değişikliği kaydedin veya iptal edin", "Hata");
            return;
        }
        if (hotDrinksGridRef.current?.instance.hasEditData()) {
            alert("Sıcak menüsü listesinde tamamlanmamış değişiklik bulunmaktadır. Lütfen ilgili değişikliği kaydedin veya iptal edin", "Hata");
            return;
        }
        if (operatingSystemsGridRef.current?.instance.hasEditData()) {
            alert("İşletim sistemleri listesinde tamamlanmamış değişiklik bulunmaktadır. Lütfen ilgili değişikliği kaydedin veya iptal edin", "Hata");
            return;
        }
        if (paymentDevicesGridRef.current?.instance.hasEditData()) {
            alert("Ödeme sistemleri listesinde tamamlanmamış değişiklik bulunmaktadır. Lütfen ilgili değişikliği kaydedin veya iptal edin", "Hata");
            return;
        }

        // update hot drinks
        const selectedHotDrinksMap = new Set<number>();
        selectedHotDrinks?.forEach((o) => selectedHotDrinksMap.add(o));
        record?.hotDrinks?.forEach((h) => h.enabled = selectedHotDrinksMap.has(h.optionNumber));

        if (isNewRecord) {
            store.insert(record!).then(cancelClick);
        } else {
            store.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`Bu otomat versiyonunu silmek istiyor musunuz?`, "Otomat Versiyonu Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    store.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    const activateClick = () => {
        confirm(`Bu otomat versiyonunu aktifleştirmek istiyor musunuz?`, "Otomat Versiyonu Aktifleştir")
            .then((dialogResult) => {
                if (dialogResult) {
                    store.activate(Number(id)).then(() => cancelClick());
                }
            });
    }

    const cloneClick = () => {
        confirm(`Bu otomat versiyonunu klonlamak istiyor musunuz?`, "Otomat Versiyonu Klonla")
            .then((dialogResult) => {
                if (dialogResult) {
                    store.clone(Number(id)).then(() => cancelClick());
                }
            });
    }

    return (
        <>
            <FormHeaderToolbar
                header={isNewRecord ? `Yeni Otomat Versiyon` : `Otomat Versiyon Güncelle - Versiyon ${record?.version}`}/>
            <Form formData={record} ref={formRef}>
                <Item editorType="dxTextBox"
                      label={{text: "Otomat"}}
                      editorOptions={
                          {
                              readOnly: true,
                              value: customerVending && customerVending.vending ?
                                  `${customerVending.vending.stockCode} - ${customerVending.vending.chassisCode} - ${customerVending.vending.model?.title}` : ""
                          }
                      }/>
                <SimpleItem dataField="description" editorType="dxTextArea"
                            editorOptions={{
                                maxLength: 1024,
                                height: 100,
                                readOnly: readOnly
                            }
                            }
                            label={{text: "Açıklama"}}/>
                <Item dataField="image" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: VendingImageStore,
                          searchEnabled: true,
                          displayExpr: 'title',
                          readOnly: readOnly
                      }}>
                    <Label text="Otomat Görseli"/>
                </Item>
                <SimpleItem dataField="targetActiveStateOn" editorType="dxDateBox" label={{text: "Hedef Aktif Tarihi"}}
                            editorOptions={{
                                openOnFieldClick: true,
                                readOnly: readOnly
                            }}>
                    <RequiredRule message="Lütfen hedef aktif tarihi giriniz"/>
                </SimpleItem>
            </Form>

            <TabPanel>
                {productsVisible && <TabPanelItem title={`ÜRÜNLER - (${record?.products?.length ?? 0})`}>
                    <DataGrid
                        dataSource={record?.products}
                        showBorders={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        height={panelHeight}
                        ref={productsGridRef}
                        paging={{enabled: false}}
                        scrolling={{useNative: true}}
                        onRowDblClick={(e) => {
                            productsGridRef?.current?.instance.editRow(e.rowIndex);
                        }}
                    >
                        {!readOnly &&
                            <GridSelection mode="single"/> &&
                            <GridToolbar>
                                <GridItem name="addRowButton" location="before" showText={"always"}/>
                            </GridToolbar> &&

                            <GridEditing
                                mode="row"
                                allowAdding={true}
                                allowUpdating={true}
                                allowDeleting={true}/>
                        }
                        <GridColumn dataField="optionNumber" caption="Sıra"
                                    width={60}
                                    dataType="number"
                                    sortIndex={1}
                                    sortOrder="asc"
                                    editorOptions={{format: "#", min: 10, max: 99}}/>
                        <GridColumn dataField="product" caption="Ürün"
                                    calculateDisplayValue={(e: CustomerVendingProduct) =>
                                        e.product ? e.product.brand?.title + " - " + e.product.title : ""
                                    }
                                    editCellRender={(cell) => productsProductEditCell(cell)}
                        >
                        </GridColumn>
                        <GridColumn dataField="railsCount" caption="Ray"
                                    width={60}
                                    dataType="number"
                                    editorOptions={{format: "#", min: 1, max: 3}}
                        />
                        <GridColumn dataField="capacity" caption="Kapasite"
                                    width={100}
                                    dataType="number"
                                    editorOptions={{format: "#", min: 1, max: 50}}/>
                        <GridColumn dataField="price" caption="Fiyat" format=",##0.## TL"
                                    width={100}
                                    dataType="number"
                                    editorOptions={{format: "#.## TL"}}/>
                    </DataGrid>
                </TabPanelItem>
                }

                {hotDrinksVisible && <TabPanelItem title={`SICAK MENÜSÜ - (${record?.hotDrinks?.length ?? 0})`}>
                    <DataGrid
                        dataSource={record?.hotDrinks}
                        showBorders={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        height={panelHeight}
                        ref={hotDrinksGridRef}
                        paging={{enabled: false}}
                        onRowDblClick={(e) => {
                            hotDrinksGridRef?.current?.instance.editRow(e.rowIndex);
                        }}
                        selectedRowKeys={selectedHotDrinks}
                        onSelectionChanged={(e) => {
                            selectedHotDrinks?.splice(0, selectedHotDrinks?.length);
                            selectedHotDrinks?.push(...e.selectedRowKeys);
                        }
                        }
                        keyExpr="optionNumber"
                    >
                        {!readOnly &&
                            <GridSelection showCheckBoxesMode="always" allowSelectAll={true} mode="multiple"
                                           selectAllMode="page"/> &&
                            <GridToolbar>
                                <GridItem name="addRowButton" location="before" showText={"always"}/>
                            </GridToolbar> &&

                            <GridEditing
                                mode="row"
                                allowAdding={true}
                                allowUpdating={true}
                                allowDeleting={true}/>
                        }
                        <GridColumn dataField="optionNumber" caption="Sıra"
                                    width={100}
                                    dataType="number"
                                    sortIndex={1}
                                    sortOrder="asc"
                                    allowEditing={false}/>

                        <GridColumn dataField="customTitle" caption="Sıcak Adı" allowSorting={false}
                                    calculateDisplayValue={(e: CustomerVendingHotDrink) => {
                                        const hotDrinkTitle = hotDrinkOptionNumbersMap?.get(e.optionNumber)?.hotDrink.title;
                                        if (e.customTitle) {
                                            return e.customTitle + ` (${hotDrinkTitle})`
                                        }
                                        return hotDrinkTitle;
                                    }
                                    }
                                    editCellRender={(cell) => customTitleEditCell(cell, hotDrinkOptionNumbersMap)}
                        >
                        </GridColumn>
                        <GridColumn dataField="price" caption="Fiyat" format=",##0.## TL"
                                    width={100}
                                    dataType="number"
                                    allowSorting={false}
                                    editorOptions={{format: "#.## TL"}}/>
                    </DataGrid>
                </TabPanelItem>
                }

                <TabPanelItem title={`İŞLETİM SİSTEMLERİ - (${record?.operatingSystems?.length ?? 0})`}>
                    <DataGrid
                        dataSource={record?.operatingSystems}
                        showBorders={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        height={panelHeight}
                        ref={operatingSystemsGridRef}
                        paging={{enabled: false}}
                        onRowDblClick={(e) => {
                            operatingSystemsGridRef?.current?.instance.editRow(e.rowIndex);
                        }}
                    >
                        {!readOnly &&
                            <GridSelection mode="single"/> &&
                            <GridToolbar>
                                <GridItem name="addRowButton" location="before" showText={"always"}/>
                            </GridToolbar> &&

                            <GridEditing
                                mode="row"
                                allowAdding={true}
                                allowUpdating={true}
                                allowDeleting={true}/>
                        }
                        <GridColumn dataField="title" caption="İşletim Sistemi"
                                    editCellRender={(cell) => operatingSystemTitleEditCell(cell)}>
                        </GridColumn>
                    </DataGrid>
                </TabPanelItem>

                <TabPanelItem title={`ÖDEME SİSTEMLERİ - (${record?.paymentDevices?.length ?? 0})`}>
                    <DataGrid
                        dataSource={record?.paymentDevices}
                        showBorders={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        height={panelHeight}
                        ref={paymentDevicesGridRef}
                        paging={{enabled: false}}
                        onRowDblClick={(e) => {
                            paymentDevicesGridRef?.current?.instance.editRow(e.rowIndex);
                        }}
                    >
                        {!readOnly &&
                            <GridSelection mode="single"/> &&
                            <GridToolbar>
                                <GridItem name="addRowButton" location="before" showText={"always"}/>
                            </GridToolbar> &&

                            <GridEditing
                                mode="row"
                                allowAdding={true}
                                allowUpdating={true}
                                allowDeleting={true}/>
                        }

                        <GridColumn dataField="chassisCode" caption="Şase Kodu"
                                    calculateDisplayValue={(e: PaymentDevice) => e?.chassisCode ?? ""}
                                    editCellRender={(cell) => paymentDeviceChasisCodeEdit(cell)}
                        />
                        <GridColumn dataField="paymentSystem.title" caption="Ödeme Sistemi" allowEditing={false}/>
                        <GridColumn dataField="mobile" caption="Hat No" width={150} allowEditing={false}/>
                    </DataGrid>
                </TabPanelItem>
            </TabPanel>

            <Toolbar>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                {currentState === "DRAFT" &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                }
                {!isNewRecord && currentState === "DRAFT" &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/> &&
                    <ToolbarItem widget="dxButton" location="after"
                                 options={{text: "Aktifleştir", icon: "check", onClick: activateClick}}/>
                }
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Klonla", icon: "fas fa-clone", onClick: cloneClick}}/>
                }
            </Toolbar>
        </>
    )
}