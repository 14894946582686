import {Customer} from "../domain/Customer";
import {BaseStore} from "./base/BaseStore";
import axios, {AxiosResponse} from "axios";


class CustomerStoreImpl extends BaseStore<Customer, number> {
    constructor() {
        super("/api/v1/customers");
    }

    getTodayVisits(): Promise<AxiosResponse<Customer[]>> {
        return axios.get(`${this.url}/today/visits`);
    }
}

export const CustomerStore = new CustomerStoreImpl();