import {
    DataGrid,
    Export,
    FilterRow,
    Item as GridItem,
    LoadPanel,
    Pager,
    Paging,
    Selection,
    Sorting,
    Toolbar
} from "devextreme-react/data-grid";
import {Button} from 'devextreme-react';
import {useNavigate} from "react-router";
import {Store} from "devextreme/data";
import React, {ReactNode, useEffect, useRef} from "react";
import {NavigationButton} from "./NavigationButton";
import {off, on} from "../utils/events";

interface Props {
    dataSource: Store,
    header: string,
    editHref?: string,
    viewHref?: string,
    newButtonHandler?: Function,
    children?: ReactNode,
    selectedHrefSuffix?: string,
    eventContext?: string
}

export function BaseDataGridList(props: Props) {
    const navigate = useNavigate();
    const gridRef = useRef<DataGrid>(null);

    const refreshClick = () => {
        gridRef?.current?.instance?.refresh();
    };

    useEffect(() => {
        if (props.eventContext) {
            on(`${props.eventContext}:refresh`, refreshClick);
        }
        return () => {
            off(`${props.eventContext}:refresh`, refreshClick);
        }
    }, [props.eventContext])

    return (
        <>
            <DataGrid
                dataSource={props.dataSource}
                showBorders={true}
                hoverStateEnabled={true}
                keyExpr="id"
                rowAlternationEnabled={true}
                remoteOperations={true}
                onSelectionChanged={({selectedRowsData}) => {
                    let href = props.editHref || props.viewHref;
                    if (href)
                        navigate(`${href}/${selectedRowsData[0].id}${props.selectedHrefSuffix || ''}`);
                }}
                ref={gridRef}
            >
                <Selection mode="single"/>
                <Export enabled={true} allowExportSelectedData={true}/>
                <LoadPanel enabled={true}/>
                <FilterRow visible={true} showOperationChooser={false}/>
                <Sorting mode="multiple"/>
                <Toolbar>
                    <GridItem location="before">
                        <span style={{fontSize: 25, marginLeft: 10}}>{props.header}</span>
                    </GridItem>

                    <GridItem location="after">
                        {props.newButtonHandler &&
                            <Button icon="plus" text="Yeni Ekle" onClick={
                                (e) => {
                                    if (props.newButtonHandler)
                                        props.newButtonHandler();
                                }
                            }/>
                        }

                        {props.editHref && !props.newButtonHandler &&
                            <NavigationButton icon="plus" text="Yeni Ekle" href={`${props.editHref}/new`}/>
                        }

                    </GridItem>

                    <GridItem location="after">
                        <Button
                            icon='refresh'
                            onClick={refreshClick}
                        />
                    </GridItem>
                    <GridItem name="exportButton"/>
                </Toolbar>
                <Paging enabled={true} defaultPageSize={10}/>
                <Pager
                    visible={true}
                    allowedPageSizes={[10, 20, 50, 100]}
                    displayMode="adaptive"
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                />

                {props.children}
            </DataGrid>
        </>
    )
}