import {
    Column,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {PaymentSystemStore} from "../../../datastore/PaymentSystemStore";

export function PaymentSystemsList() {
    return (
        <BaseDataGridList
            dataSource={PaymentSystemStore}
            header="Ödeme Sistemleri"
            editHref="/payment-systems"
        >
            <Column dataField="title" caption="Ödeme Sistemi Adı"/>
        </BaseDataGridList>
    )
}
