import ArrayStore, {Options} from "devextreme/data/array_store";

export class EnumStore<TItem, TKey> extends ArrayStore<TItem, TKey> {
    private idMap: Map<TKey, TItem> = new Map<TKey, TItem>();

    constructor(options?: Options<TItem, TKey>) {
        super(options);

        options?.data?.forEach((i: any) => {
            this.idMap.set(i[options?.key as TKey], i);
        });
    }

    findByKey(id: TKey): TItem | undefined {
        return this.idMap.get(id);
    }

    joinArray(items: Array<TKey>, displayField: string) {
        if(!items || items.length === 0) return "";
        return items
            .map((i) => (this.idMap.get(i) as any)?.[displayField])
            .join(", ")
    }
}