import {
    Column, Lookup,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import React from "react";
import {TaskScopeStore} from "../../../datastore/TaskScopeStore";
import {TechnicianTaskStore} from "../../../datastore/TechnicianTaskStore";
import {TaskStateStore} from "../../../datastore/TaskStateStore";
import {TaskOptionalityStore} from "../../../datastore/TaskOptionalityStore";

export function TechnicianTasksList() {
    return (
        <BaseDataGridList
            dataSource={TechnicianTaskStore}
            header="Teknisyen Görevleri"
            editHref="/technician-tasks"
        >
            <Column dataField="technician.name" caption="Teknisyen"/>
            <Column dataField="task.title" caption="Görev"/>
            <Column dataField="task.scope" caption="Kapsam" width="100">
                <Lookup dataSource={TaskScopeStore} valueExpr="id" displayExpr="title"/>
            </Column>
            <Column dataField="state" caption="Durum" width="100">
                <Lookup dataSource={TaskStateStore} valueExpr="id" displayExpr="title"/>
            </Column>
            <Column dataField="targetStartOn" caption="Hedef Tarih" dataType="date" allowFiltering={false} width="120"/>
            <Column dataField="completedAt" caption="Tamamlandı" dataType="datetime" allowFiltering={false} width="120"/>
            <Column dataField="optionality" caption="Opsiyonellik" width="100">
                <Lookup dataSource={TaskOptionalityStore} valueExpr="id" displayExpr="title"/>
            </Column>
            <Column dataField="customer.title" caption="Müşteri" width="150"/>
            <Column dataField="vending.stockCode" caption="Otomat" width="120"/>
            <Column dataField="vehicle.licensePlate" caption="Araç" width="120"/>
        </BaseDataGridList>
    )
}
