import {
    Column,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {VehicleStore} from "../../../datastore/VehicleStore";

export function VehicleInventoriesList() {
    return (
        <BaseDataGridList
            dataSource={VehicleStore}
            header="Araç Depoları"
            editHref="/vehicles/inventories"
        >
            <Column dataField="licensePlate" caption="Araç Plakası"/>
        </BaseDataGridList>
    )
}
