import Form, {GroupItem, Item, Label, RangeRule, RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import React, {useEffect, useRef, useState} from "react";
import {Order} from "../../../domain/Order";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {OrderStore} from "../../../datastore/OrderStore";
import {
    Column as GridColumn,
    DataGrid,
    Editing as GridEditing,
    Item as GridItem,
    Lookup,
    Selection as GridSelection,
    Summary,
    Toolbar as GridToolbar,
    TotalItem
} from "devextreme-react/data-grid";
import {Button, Popup} from "devextreme-react";
import {OrderProduct} from "../../../domain/OrderProduct";
import {Product} from "../../../domain/Product";
import {ProductStore} from "../../../datastore/ProductStore";
import {SupplierStore} from "../../../datastore/SupplierStore";
import {
    ProductUnitPackage,
    ProductUnitStore
} from "../../../datastore/ProductUnitStore";
import Guid from "devextreme/core/guid";
import {isFormValid} from "../../../utils/utils";
import {confirm} from "devextreme/ui/dialog";
import {formatNumber} from "devextreme/localization";

export function OrdersEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [selectedOrderProduct, setSelectedOrderProduct] = useState<OrderProduct>();
    const [editFormTitle, setEditFormTitle] = useState<string>();
    const [editFormSaveTitle, setEditFormSaveTitle] = useState<string>();
    const [record, setRecord] = useState<Order>();
    const [addNewProduct, setAddNewProduct] = useState(false);
    const orderProductEditForm = useRef<Form>(null);

    const {id} = useParams();
    const isNewRecord = !id;

    useEffect(() => {
        if (!isNewRecord) {
            OrderStore.byKey(Number(id)).then((data) => {
                if (!data.products) data.products = [];
                data.products.map((p) => p.id = new Guid().toString());
                setRecord(data);
            });
        } else {
            setRecord({
                products: []
            });
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/orders")
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (isNewRecord) {
            OrderStore.insert(record!).then(cancelClick);
        } else {
            OrderStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`<b>${record?.id}</b> nolu siparişi silmek istiyor musunuz?`, "Sipariş Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    OrderStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    const addProductClick = () => {
        setAddNewProduct(true);
        setEditFormTitle("Ürün Ekle");
        setEditFormSaveTitle("Ürün Ekle");
        setSelectedOrderProduct({
            id: new Guid().toString(),
            unit: ProductUnitPackage
        });
        setEditFormVisible(true);
    }

    const selectProduct = (e: OrderProduct) => {
        setAddNewProduct(false);
        setEditFormTitle("Ürün Güncelle");
        setEditFormSaveTitle("Ürün Güncelle");
        setSelectedOrderProduct(Object.assign({}, e));
        setEditFormVisible(true);
    }

    const cancelProductClick = () => {
        setEditFormVisible(false);
    }

    const saveProductClick = () => {
        if (!isFormValid(orderProductEditForm)) return;
        const orderProduct = Object.assign({}, selectedOrderProduct);
        if (addNewProduct) {
            record!.products = (record?.products || []).concat(orderProduct!);
        } else {
            const index = record!.products?.findIndex((p) => p.id === orderProduct?.id);
            if (index !== undefined && index > -1) {
                record!.products?.splice(index, 1, orderProduct!);
                // refresh
                record!.products = (record?.products || []).concat([]);
            }
        }
        setEditFormVisible(false);
    }

    const calculateOrderProductAmounts = () => {
        const values = {
            amount: 0,
            taxAmount: 0,
            totalAmount: 0
        };
        if (selectedOrderProduct) {
            values.amount = (selectedOrderProduct.quantity || 0) * (selectedOrderProduct.price || 0);
            values.taxAmount = (selectedOrderProduct.taxRate || 0) * values.amount;
            values.totalAmount = values.amount + values.taxAmount;
        }
        orderProductEditForm?.current?.instance.updateData(values);
    }

    const formatTotal = (data: any): string => {
        return formatNumber(data.value, ",##0.## TL");
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni Sipariş" : "Sipariş Güncelle"}/>
            <Form ref={formRef}
                  formData={record}
                  onContentReady={(e) => {
                      e.component.getEditor("invoiceNumber")?.focus();
                  }}
            >
                <GroupItem colCount={3}>
                    {!isNewRecord &&
                        <SimpleItem dataField="id" editorType="dxTextBox" label={{text: "Sipariş No"}}
                                    editorOptions={{readOnly: true}}/>
                    }
                    <SimpleItem dataField="invoiceNumber" editorType="dxTextBox" label={{text: "Fatura No"}}/>
                    <Item dataField="supplier" editorType="dxSelectBox"
                          editorOptions={{
                              dataSource: SupplierStore,
                              searchEnabled: true,
                              displayExpr: 'title'
                          }}>
                        <Label text="Tedarikçi"/>
                    </Item>
                </GroupItem>
                <GroupItem colCount={2}>
                    <SimpleItem dataField="orderedAt" editorType="dxDateBox" label={{text: "Sipariş Tarihi"}}
                                editorOptions={{openOnFieldClick: true}}/>
                    <SimpleItem dataField="deliveredAt" editorType="dxDateBox" label={{text: "Teslim Tarihi & Saati"}}
                                editorOptions={
                                    {
                                        dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
                                        type: 'datetime',
                                        openOnFieldClick: true
                                    }
                                }/>
                </GroupItem>

                <GroupItem caption="Ürünler">
                    <DataGrid
                        dataSource={record?.products}
                        showBorders={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        height={300}
                        onRowClick={(e) => {
                            selectProduct(e.data);
                        }}
                    >
                        <GridSelection mode="single"/>
                        <GridEditing
                            mode="row"
                            allowDeleting={true}/>
                        <GridToolbar>
                            <GridItem location="before">
                                <Button
                                    icon='plus'
                                    text="Ürün Ekle"
                                    onClick={addProductClick}
                                />
                            </GridItem>
                        </GridToolbar>
                        <GridColumn dataField="product.brand.title" caption="Marka Adı"/>
                        <GridColumn dataField="product.title" caption="Ürün Adı"/>
                        <GridColumn dataField="price" caption="Birim Fiyat" format="#0.##### TL" width="150"/>
                        <GridColumn dataField="quantity" caption="Miktar" format="#0.##" width="80"/>
                        <GridColumn dataField="unit" caption="Birim" width="100">
                            <Lookup dataSource={ProductUnitStore} valueExpr="id" displayExpr="title"/>
                        </GridColumn>
                        <GridColumn dataField="amount" caption="Tutar" format=",##0.## TL" width="120"/>
                        <GridColumn dataField="taxRate" caption="KDV" format="% #" width="60"/>
                        <GridColumn dataField="taxAmount" caption="KDV Tutarı" format=",##0.## TL" width="120"/>
                        <GridColumn dataField="totalAmount" caption="Toplam Tutar" format=",##0.## TL" width="120"/>
                        <Summary>
                            <TotalItem
                                customizeText={formatTotal}
                                column="amountSum"
                                summaryType="sum"
                            />
                            <TotalItem
                                customizeText={formatTotal}
                                column="taxAmountSum"
                                summaryType="sum"
                            />
                            <TotalItem
                                customizeText={formatTotal}
                                column="totalAmountSum"
                                summaryType="sum"
                            />
                        </Summary>
                    </DataGrid>
                </GroupItem>


            </Form>
            <Toolbar>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>

            <Popup
                visible={editFormVisible}
                showCloseButton={true}
                onHiding={cancelProductClick}
                title={editFormTitle}
                width={800}
                height={470}
            >
                <Form formData={selectedOrderProduct} ref={orderProductEditForm}>
                    <SimpleItem dataField="product"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    dataSource: ProductStore,
                                    displayExpr: (data: Product) => {
                                        return data ? data.brand?.title + " - " + data.title : ""
                                    },
                                    searchExpr: "title",
                                    searchEnabled: true,
                                    minSearchLength: 1,
                                    onValueChanged: (e: any) => {
                                        const product = e.value as Product;
                                        const data: any = {};
                                        if (product) {
                                            if (product.taxRate) data.taxRate = product.taxRate;
                                            if (product.piecePrice) data.price = product.piecePrice;
                                        }
                                        if (Object.keys(data).length > 0) {
                                            orderProductEditForm?.current?.instance.updateData(data);
                                            calculateOrderProductAmounts();
                                        }
                                    }
                                }}
                    >
                        <RequiredRule message="Lütfen ürün seçiniz"/>
                        <Label text="Ürün"/>
                    </SimpleItem>
                    <GroupItem colCount={3}>
                        <SimpleItem dataField="price" editorType="dxNumberBox"
                                    editorOptions={
                                        {
                                            format: "#0.##### TL",
                                            min: 0,
                                            onValueChanged: calculateOrderProductAmounts
                                        }
                                    }>
                            <RequiredRule message="Lütfen birim fiyat giriniz"/>
                            <Label text="Birim Fiyat"/>
                        </SimpleItem>
                        <SimpleItem dataField="quantity" editorType="dxNumberBox"
                                    editorOptions={
                                        {
                                            format: "#0.##",
                                            min: 0,
                                            onValueChanged: calculateOrderProductAmounts
                                        }
                                    }>
                            <RequiredRule message="Lütfen miktar giriniz"/>
                            <RangeRule min={1} message="Lütfen miktar giriniz"/>
                            <Label text="Miktar"/>
                        </SimpleItem>
                        <Item dataField="unit" editorType="dxSelectBox"
                              editorOptions={{
                                  dataSource: ProductUnitStore,
                                  displayExpr: 'title',
                                  valueExpr: 'id',
                                  onValueChanged: calculateOrderProductAmounts
                              }}>
                            <RequiredRule message="Lütfen birim seçiniz"/>
                            <Label text="Birim"/>
                        </Item>
                    </GroupItem>
                    <GroupItem colCount={4}>
                        <SimpleItem dataField="amount" editorType="dxNumberBox"
                                    editorOptions={{format: ",##0.## TL", min: 0, readOnly: true}}>
                            <Label text="Tutar"/>
                        </SimpleItem>
                        <SimpleItem dataField="taxRate" editorType="dxNumberBox"
                                    editorOptions={{format: "% #", min: 0, readOnly: true}}>
                            <Label text="KDV Oranı"/>
                        </SimpleItem>
                        <SimpleItem dataField="taxAmount" editorType="dxNumberBox"
                                    editorOptions={{format: ",##0.## TL", min: 0, readOnly: true}}>
                            <Label text="KDV Tutarı"/>
                        </SimpleItem>
                        <SimpleItem dataField="totalAmount" editorType="dxNumberBox"
                                    editorOptions={{format: ",##0.## TL", min: 0, readOnly: true}}>
                            <Label text="Toplam Tutar"/>
                        </SimpleItem>
                    </GroupItem>
                </Form>
                <Toolbar>
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "İptal", icon: "back", onClick: cancelProductClick}}/>
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: editFormSaveTitle, icon: "save", onClick: saveProductClick}}/>
                </Toolbar>
            </Popup>
        </>
    )
}