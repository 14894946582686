import React from 'react';
import './profile.scss';
import Form from 'devextreme-react/form';
import {useAuth} from "../../contexts/auth";

export default function Profile() {
  const {user} = useAuth();

  const employee = {
    Ad: user.firstName,
    Soyad: user.lastName,
    Email: user.email
  };

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Profile</h2>

      <div className={'content-block dx-card responsive-paddings'}>
        <div className={'form-avatar'}>
          <img
            alt={''}
            src={`https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/${
                'images/employees/06.png'
            }`}
          />
        </div>
        <span>{user.username}</span>
      </div>

      <div className={'content-block dx-card responsive-paddings'}>
        <Form
          id={'form'}
          defaultFormData={employee}
          labelLocation={'top'}
          colCountByScreen={colCountByScreen}
        />
      </div>
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4
};
