import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React, {useEffect, useState} from 'react';
import {HashRouter as Router} from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import {NavigationProvider} from './contexts/navigation';
import {AuthProvider, useAuth} from './contexts/auth';
import {useScreenSizeClass} from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import {AxiosProvider} from "./contexts/axios";
import {off, on} from "./utils/events";
import {LOAD_PANEL_HIDE, LOAD_PANEL_SHOW} from "./utils/utils";

function App() {
    const {user, loading} = useAuth();
    const [loadPanelVisible, setLoadPanelVisible] = useState(false);
    const showLoadPanel = () => {
        setLoadPanelVisible(true);
    }
    const hideLoadPanel = () => {
        setLoadPanelVisible(false);
    }
    useEffect(() => {
        on(LOAD_PANEL_SHOW, showLoadPanel);
        on(LOAD_PANEL_HIDE, hideLoadPanel);

        return () => {
            off(LOAD_PANEL_SHOW, showLoadPanel);
            off(LOAD_PANEL_HIDE, hideLoadPanel);
        }
    }, []);

    if (loading) {
        return <LoadPanel visible={true}/>;
    }

    if (user) {
        return <>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position="center"
                onHiding={hideLoadPanel}
                visible={loadPanelVisible}
                showIndicator={true}
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />
            <Content/>
        </>;
    }

    return <UnauthenticatedContent/>;
}

export default function Root() {
    const screenSizeClass = useScreenSizeClass();
    return (
        <Router>
            <AxiosProvider>
                <AuthProvider>
                    <NavigationProvider>
                        <div className={`app ${screenSizeClass}`}>
                            <App/>
                        </div>
                    </NavigationProvider>
                </AuthProvider>
            </AxiosProvider>
        </Router>
    );
}
