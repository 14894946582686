import {
    Column,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {BrandStore} from "../../../datastore/BrandStore";

export function BrandsList() {
    return (
        <BaseDataGridList
            dataSource={BrandStore}
            header="Markalar"
            editHref="/brands"
        >
            <Column dataField="title" caption="Marka Adı"/>
        </BaseDataGridList>
    )
}
