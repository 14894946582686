import Form, {Item, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {isFormValid} from "../../../utils/utils";
import {confirm} from 'devextreme/ui/dialog';
import {TechnicianHandheld} from "../../../domain/TechnicianHandheld";
import {TechnicianHandheldStore} from "../../../datastore/TechnicianHandheldStore";
import {TechnicianStore} from "../../../datastore/TechnicianStore";
import {HandheldTerminalStore} from "../../../datastore/HandheldTerminalStore";
import {Technician} from "../../../domain/Technician";
import {HandheldTerminal} from "../../../domain/HandheldTerminal";
import {HandheldAppTypeStore} from "../../../datastore/HandheldAppTypeStore";

export function TechnicianHandheldsEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const [record, setRecord] = useState<TechnicianHandheld>();
    const {id} = useParams();
    const isNewRecord = !id;

    useEffect(() => {
        if (!isNewRecord) {
            TechnicianHandheldStore.byKey(Number(id)).then((data) => {
                setRecord(data);
            });
        } else {
            setRecord({});
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/technician-handhelds")
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (isNewRecord) {
            TechnicianHandheldStore.insert(record!).then(cancelClick);
        } else {
            TechnicianHandheldStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`Teknisyen el terminalini silmek istiyor musunuz?`, "Teknisyen El Terminali Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    TechnicianHandheldStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni Teknisyen El Terminali" : "Teknisyen El Terminali Güncelle"}/>
            <Form ref={formRef}
                  formData={record}
                  onContentReady={(e) => {
                      e.component.getEditor("title")?.focus();
                  }}
            >
                <Item dataField="technician" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: TechnicianStore,
                          displayExpr: (data: Technician) => {
                              return data ? data.personalCode + " - " + data.name : ""
                          },
                          searchExpr: 'search',
                          searchEnabled: true
                      }}>
                    <Label text="Teknisyen"/>
                    <RequiredRule message="Teknisyen seçiniz"/>
                </Item>

                <Item dataField="handheldTerminal" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: HandheldTerminalStore,
                          displayExpr: (data: HandheldTerminal) => {
                              return data ? data.stockCode + " - " + data.serialNumber : ""
                          },
                          searchExpr: 'search',
                          searchEnabled: true
                      }}>
                    <Label text="El Terminali"/>
                    <RequiredRule message="El terminali seçiniz"/>
                </Item>

                <SimpleItem dataField="issueDate" editorType="dxDateBox" label={{text: "Veriliş Tarihi"}}
                            editorOptions={{openOnFieldClick: true}}/>

                <Item dataField="chargeKitGiven" editorType="dxCheckBox"
                      editorOptions={{
                          text: "Şarj Kiti Verildi"
                      }}>
                    <Label text=" "/>
                </Item>

                <Item dataField="autoKitGiven" editorType="dxCheckBox"
                      editorOptions={{
                          text: "Araç Kiti Verildi"
                      }}>
                    <Label text=" "/>
                </Item>
                <Item dataField="appType" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: HandheldAppTypeStore,
                          displayExpr: 'title',
                          valueExpr: 'id'
                      }}>
                    <Label text="Uygulama"/>
                    <RequiredRule message="Lütfen uygulama seçiniz"/>
                </Item>

                <SimpleItem dataField="returnDate" editorType="dxDateBox" label={{text: "İade Tarihi"}}
                            editorOptions={{openOnFieldClick: true}}/>
            </Form>
            <Toolbar>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>
        </>
    )

    // TODO broken, given, return states are missing
}