import Form, {GroupItem, Item, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {Product} from "../../../domain/Product";
import {ProductCategoriesStore} from "../../../datastore/ProductCategoriesStore";
import {BrandStore} from "../../../datastore/BrandStore";
import {ProductStore} from "../../../datastore/ProductStore";
import {isFormValid} from "../../../utils/utils";
import {confirm} from "devextreme/ui/dialog";

export function ProductsEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const [record, setRecord] = useState<Product>();
    const {id} = useParams();
    const isNewRecord = !id;

    useEffect(() => {
        if (!isNewRecord) {
            ProductStore.byKey(Number(id)).then((data) => {
                setRecord(data);
            });
        } else {
            setRecord({});
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/products")
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (isNewRecord) {
            ProductStore.insert(record!).then(cancelClick);
        } else {
            ProductStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`<b>${record?.title}</b> adlı ürünü silmek istiyor musunuz?`, "Ürün Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    ProductStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni Ürün" : "Ürün Güncelle"}/>
            <Form ref={formRef}
                  formData={record}
                  onContentReady={(e) => {
                      e.component.getEditor("title")?.focus();
                  }}
            >
                <GroupItem colCount={3}>
                    <SimpleItem dataField="title" editorType="dxTextBox">
                        <Label text="Ürün Adı"/>
                        <RequiredRule message="Lütfen ürün adı giriniz"/>
                    </SimpleItem>
                    <Item dataField="brand" editorType="dxSelectBox"
                          editorOptions={{
                              dataSource: BrandStore,
                              searchEnabled: true,
                              displayExpr: 'title'
                          }}>
                        <Label text="Marka"/>
                        <RequiredRule message="Lütfen marka seçiniz"/>
                    </Item>
                    <Item dataField="category" editorType="dxSelectBox"
                          editorOptions={{
                              dataSource: ProductCategoriesStore,
                              displayExpr: 'title',
                              valueExpr: 'id'
                          }}>
                        <Label text="Ürün Kategorisi"/>
                        <RequiredRule message="Ürün kategorisi seçiniz"/>
                    </Item>
                </GroupItem>
                <GroupItem caption="Barkod Bilgileri" colCount={3}>
                    <SimpleItem dataField="pieceBarcode"><Label text="Adet Barkodu"/></SimpleItem>
                    <SimpleItem dataField="boxBarcode"><Label text="Kutu Barkodu"/></SimpleItem>
                    <SimpleItem dataField="packageBarcode"><Label text="Koli Barkodu"/></SimpleItem>
                </GroupItem>
                <GroupItem caption="Finansal" colCount={2}>
                    <SimpleItem dataField="taxRate" editorType="dxNumberBox"
                                editorOptions={{format: "% #", min: 0, max: 0.50, step: 0.01}}><Label
                        text="KDV Oranı"/></SimpleItem>
                    <SimpleItem dataField="piecePrice" editorType="dxNumberBox"
                                editorOptions={{format: "#0.##### TL", min: 0}}><Label
                        text="Alış Fiyatı"/></SimpleItem>
                </GroupItem>
                <GroupItem caption="Adet Bilgileri" colCount={3}>
                    <SimpleItem dataField="totalPiecesInBox" editorType="dxNumberBox"
                                editorOptions={{min: 0}}><Label
                        text="Kutudaki Toplam Adet"/></SimpleItem>
                    <SimpleItem dataField="totalBoxesInPackage" editorType="dxNumberBox"
                                editorOptions={{min: 0}}><Label
                        text="Kolideki Toplam Kutu"/></SimpleItem>
                    <SimpleItem dataField="totalPiecesInPackage" editorType="dxNumberBox"
                                editorOptions={{min: 0}}><Label
                        text="Kolideki Toplam Adet"/></SimpleItem>
                </GroupItem>
                <GroupItem caption="Ağırlık Bilgileri" colCount={3}>
                    <SimpleItem dataField="pieceWeight" editorType="dxNumberBox"
                                editorOptions={{format: "#.## g", min: 0}}><Label text="Adet Ağırlığı"/></SimpleItem>
                    <SimpleItem dataField="packageWeight" editorType="dxNumberBox"
                                editorOptions={{format: "#0.## kg", min: 0}}><Label
                        text="Kolideki Ağırlığı"/></SimpleItem>
                </GroupItem>
            </Form>
            <Toolbar>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>
        </>
    )
}