import {EnumStore} from "./base/EnumStore";

export const ProductCategoriesStore = new EnumStore({
        key: "id",
        data: [
            {"id": "HOT", "title": "Sıcak"},
            {"id": "SNACK", "title": "Atıştırmalık"},
            {"id": "COLD", "title": "Soğuk"},
        ]
    }
);