import Form, {Item, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import {useEffect, useRef, useState} from "react";
import {Vending} from "../../../domain/Vending";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {VendingStateStore} from "../../../datastore/VendingStateStore";
import {VendingModelStore} from "../../../datastore/VendingModelStore";
import {VendingStore} from "../../../datastore/VendingStore";
import {isFormValid} from "../../../utils/utils";
import {confirm} from "devextreme/ui/dialog";

export function VendingsEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const [record, setRecord] = useState<Vending>();
    const {id} = useParams();
    const isNewRecord = !id;

    useEffect(() => {
        if (!isNewRecord) {
            VendingStore.byKey(Number(id)).then((data) => {
                setRecord(data);
            });
        } else {
            setRecord({});
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/vendings")
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (isNewRecord) {
            VendingStore.insert(record!).then(cancelClick);
        } else {
            VendingStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`<b>${record?.stockCode}</b> kodlu otomatı silmek istiyor musunuz?`, "Otomat Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    VendingStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni Otomat" : "Otomat Güncelle"}/>
            <Form ref={formRef}
                  formData={record}
                  onContentReady={(e) => {
                      e.component.getEditor("stockCode")?.focus();
                  }}
            >
                <SimpleItem dataField="stockCode" editorType="dxTextBox" label={{text: "Barkod"}}>
                    <RequiredRule message="Lütfen barkodu giriniz"/>
                </SimpleItem>
                <SimpleItem dataField="chassisCode" editorType="dxTextBox" label={{text: "Şase Kodu"}}>
                    <RequiredRule message="Lütfen şase kodu giriniz"/>
                </SimpleItem>
                <Item dataField="model" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: VendingModelStore,
                          displayExpr: 'title',
                          searchEnabled: true
                      }}>
                    <Label text="Otomat Modeli"/>
                </Item>
                {!isNewRecord &&
                    <Item dataField="state" editorType="dxSelectBox"
                          editorOptions={{
                              dataSource: VendingStateStore,
                              displayExpr: 'title',
                              valueExpr: 'id',
                              readOnly: true
                          }}>
                        <Label text="Durum"/>
                    </Item>
                }
                <SimpleItem dataField="notes" editorType="dxTextArea" label={{text: "Notlar"}}/>
            </Form>
            <Toolbar>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>
        </>
    )
}