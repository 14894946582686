import {
    Column,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {TechnicianStore} from "../../../datastore/TechnicianStore";

export function TechniciansList() {
    return (
        <BaseDataGridList
            dataSource={TechnicianStore}
            header="Teknisyenler"
            editHref="/technicians"
        >
            <Column dataField="name" caption="Teknisyen Adı"/>
            <Column dataField="personalCode" caption="Personel Kodu"/>
        </BaseDataGridList>
    )
}
