import {EnumStore} from "./base/EnumStore";

export const OrderStateStore = new EnumStore({
        key: "id",
        data: [
            {"id": "WAITING", "title": "Bekliyor"},
            {"id": "COMPLETED", "title": "Tamamlandı"},
            {"id": "CANCELLED", "title": "İptal"},
        ]
    }
);