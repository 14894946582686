import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {WarehouseInventoriesStore, WarehouseStore} from "../../../datastore/WarehouseStore";
import {Column as GridColumn, DataGrid} from "devextreme-react/data-grid";
import {Button} from "devextreme-react";
import {Warehouse} from "../../../domain/Warehouse";

export function WarehousesState() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [record, setRecord] = useState<Warehouse>();

    const editClick = () => {
        navigate(`/warehouses/${id}`);
    }
    const listClick = () => {
        navigate(`/warehouses`);
    }

    useEffect(() => {
        WarehouseStore.byKey(Number(id)).then((data) => {
            setRecord(data);
        });
    }, [id]);

    return (
        <>
            <Toolbar>
                <ToolbarItem location="before">
                    <Button text="DEPO LİSTESİ" onClick={listClick} icon="undo"/>
                </ToolbarItem>
                <ToolbarItem location="before">
                    <Button text="DEPO GÜNCELLE" onClick={editClick} icon="edit"/>
                </ToolbarItem>
            </Toolbar>

            <FormHeaderToolbar header={`Depo Ürünleri - ${record?.title}`}/>
            <DataGrid
                dataSource={WarehouseInventoriesStore(Number(id))}
                showBorders={true}
                hoverStateEnabled={true}
                rowAlternationEnabled={true}
            >
                <GridColumn dataField="product.brand.title" caption="Marka Adı"/>
                <GridColumn dataField="product.title" caption="Ürün Adı"/>
                <GridColumn dataField="quantity" caption="Miktar" format=",##0" width="150"/>
            </DataGrid>
        </>
    )
}