export const navigation = [
  {
    text: 'Ana Sayfa',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Saha Operasyonu',
    icon: 'fas fa-briefcase',
    items: [
      {
        text: 'Müşteriler',
        path: '/customers'
      },
      {
        text: 'Müşteri Otomatları',
        path: '/customer-vendings'
      },
      {
        text: 'Teknisyen Görevleri',
        path: '/technician-tasks'
      },
      {
        text: 'Otomat Ziyaretleri',
        path: '/customer-vending-visits'
      },
      {
        text: 'Otomat Dolumları',
        path: '/customer-vending-loads'
      },
      {
        text: 'Otomat İadeleri',
        path: '/customer-vending-unloads'
      }
    ]
  },
  {
    text: 'Ürün Operasyonu',
    icon: 'fas fa-boxes-packing',
    items: [
      {
        text: 'Ürün Satışları',
        path: '/product-sales'
      },
      {
        text: 'Siparişler',
        path: '/orders'
      },
      {
        text: 'Teknisyen Siparişleri',
        path: '/technician-orders'
      },
      {
        text: 'Ürün Hareketleri',
        path: '/inventory-transactions'
      }
    ]
  },
  {
    text: 'Personel',
    icon: 'fas fa-users',
    items: [
      {
        text: 'Teknisyenler',
        path: '/technicians'
      },
      {
        text: 'Teknisyen Araç Atama',
        path: '/technician-vehicles'
      },
      {
        text: 'Teknisyen Terminal Atama',
        path: '/technician-handhelds'
      },
    ]
  },
  {
    text: 'Tanımlar',
    icon: 'fas fa-database',
    items: [
      {
        text: 'Markalar',
        path: '/brands'
      },
      {
        text: 'Ürünler',
        path: '/products'
      },
      {
        text: 'Otomat Menüleri',
        path: '/hot-drinks'
      },
      {
        text: 'Otomat Modelleri',
        path: '/vending-models'
      },
      {
        text: 'İşletim Sistemleri',
        path: '/operating-systems'
      },
      {
        text: 'Ödeme Sistemleri',
        path: '/payment-systems'
      },
      {
        text: 'Otomat Görselleri',
        path: '/vending-images'
      },
      {
        text: 'Bölgeler',
        path: '/regions'
      },
      {
        text: 'Tedarikçiler',
        path: '/suppliers'
      },
      {
        text: 'Araçlar',
        path: '/vehicles'
      },
      {
        text: 'İade Nedenleri',
        path: '/reason-for-returns'
      },
      {
        text: 'Görev Tanımları',
        path: '/task-definitions'
      },
      {
        text: 'Nakliye Şekilleri',
        path: '/transport-types'
      }
    ]
  },
  {
    text: 'Depo',
    icon: 'fas fa-warehouse',
    items: [
      {
        text: 'Depolar',
        path: '/warehouses'
      },
      {
        text: 'Araç Depoları',
        path: '/vehicles/inventories'
      },
      {
        text: 'Otomatlar',
        path: '/vendings'
      },
      {
        text: 'Ödeme Cihazları',
        path: '/payment-devices'
      },
      {
        text: 'El Terminalleri',
        path: '/handheld-terminals'
      },
    ]
  }
  // {
  //   text: 'Yönetim',
  //   icon: 'fas fa-gear',
  //   items: [
  //     {
  //       text: 'Kullanıcılar',
  //       path: '/users'
  //     },
  //   ]
  // }
  ];
