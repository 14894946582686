import Form, {RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem, Item} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import {useEffect, useRef, useState} from "react";
import {Vehicle} from "../../../domain/Vehicle";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {VehicleStore} from "../../../datastore/VehicleStore";
import {isFormValid} from "../../../utils/utils";
import {confirm} from "devextreme/ui/dialog";

export function VehiclesEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const [record, setRecord] = useState<Vehicle>();
    const {id} = useParams();
    const isNewRecord = !id;

    useEffect(() => {
        if (!isNewRecord) {
            VehicleStore.byKey(Number(id)).then((data) => {
                setRecord(data);
            });
        } else {
            setRecord({});
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/vehicles")
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (isNewRecord) {
            VehicleStore.insert(record!).then(cancelClick);
        } else {
            VehicleStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`<b>${record?.licensePlate}</b> plakalı aracı silmek istiyor musunuz?`, "Araç Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    VehicleStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni Araç" : "Araç Güncelle"}/>
            <Form ref={formRef}
                  formData={record}
                  onContentReady={(e) => {
                      e.component.getEditor("licensePlate")?.focus();
                  }}
            >
                <SimpleItem dataField="licensePlate" editorType="dxTextBox" label={{text: "Araç Plakası"}}>
                    <RequiredRule message="Lütfen araç plakası giriniz"/>
                </SimpleItem>
            </Form>
            <Toolbar>
                <Item widget="dxButton" location="before" options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <Item widget="dxButton" location="before" options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>
        </>
    )
}