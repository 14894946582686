import {BaseStore} from "./base/BaseStore";
import {Order} from "../domain/Order";
import axios, {AxiosResponse} from "axios";
import {formatDate} from "devextreme/localization";

class OrderStoreImpl extends BaseStore<Order, number> {
    constructor() {
        super("/api/v1/orders");
    }

    waiting(id: number): Promise<AxiosResponse<void>> {
        return axios.post(`${this.url}/${id}/waiting`);
    }

    complete(id: number, targetWarehouseId: number, deliveredAt?: Date): Promise<AxiosResponse<void>> {
        const queryParams = [];
        queryParams.push(`targetWarehouse=${targetWarehouseId}`);
        if (deliveredAt) {
            queryParams.push("deliveredAt=" + formatDate(deliveredAt!, "yyyy-MM-ddTHH:mm:ss"));
        }
        return axios.post(`${this.url}/${id}/complete?` + queryParams.join('&'));
    }

    cancel(id: number): Promise<AxiosResponse<void>> {
        return axios.post(`${this.url}/${id}/cancel`);
    }
}

export const OrderStore = new OrderStoreImpl();