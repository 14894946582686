import {Column} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import React from "react";
import {CustomerVendingLoadStore} from "../../../datastore/CustomerVendingLoadStore";

export function CustomerVendingLoadsList() {
    return (
        <>
            <BaseDataGridList
                dataSource={CustomerVendingLoadStore}
                header="Otomat Dolumları"
                viewHref="/customer-vending-loads"
            >
                <Column dataField="customerVendingVersion.customerVending.customer.title" caption="Müşteri" allowFiltering={false}/>
                <Column dataField="customerVendingVersion.customerVending.vending.stockCode" caption="Otomat" allowFiltering={false}/>
                <Column dataField="technician.name" caption="Teknisyen" allowFiltering={false}/>
                <Column dataField="startedAt" caption="Başlangıç" dataType="datetime" allowFiltering={false}/>
                <Column dataField="completedAt" caption="Bitiş" dataType="datetime" allowFiltering={false}/>
            </BaseDataGridList>
        </>
    )
}
