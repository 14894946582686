import {Column,} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {HotDrinkStore} from "../../../datastore/HotDrinkStore";

export function HotDrinksList() {
    return (
        <BaseDataGridList
            dataSource={HotDrinkStore}
            header="Otomat Menüleri"
            editHref="/hot-drinks"
        >
            <Column dataField="title" caption="Otomat Menüsü Adı"/>
        </BaseDataGridList>
    )
}
