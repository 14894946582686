import {
    Column, Lookup,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {HandheldTerminalStateStore} from "../../../datastore/HandheldTerminalStateStore";
import {Vending} from "../../../domain/Vending";
import {HandheldTerminalStore} from "../../../datastore/HandheldTerminalStore";

export function HandheldTerminalsList() {
    return (
        <BaseDataGridList
            dataSource={HandheldTerminalStore}
            header="El Terminalleri"
            editHref="/handheld-terminals"
        >
            <Column dataField="stockCode" caption="Stok Kodu"/>
            <Column dataField="serialNumber" caption="Seri No"/>
            <Column dataField="model" caption="Model"/>
            <Column dataField="gsmNumber" caption="GSM"/>
            <Column dataField="state" caption="Durum"
                    calculateDisplayValue={(data: Vending) => HandheldTerminalStateStore.findByKey(data.state)?.title}
                    calculateCellValue={(data: Vending) => HandheldTerminalStateStore.findByKey(data.state)?.title}
            >
                <Lookup dataSource={HandheldTerminalStateStore} valueExpr="id" displayExpr="title"/>
            </Column>
        </BaseDataGridList>
    )
}
