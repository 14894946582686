import {EnumStore} from "./base/EnumStore";

export const VendingStateStore = new EnumStore({
        key: "id",
        data: [
            {"id": "IN_STOCK", "title": "Stokta"},
            {"id": "AT_CUSTOMER", "title": "Müşteride"},
            {"id": "AT_SERVICE", "title": "Serviste"},
            {"id": "SOLD", "title": "Satıldı"},
            {"id": "SCRAP", "title": "Hurda"},
            {"id": "BROKEN", "title": "Bozuldu"},
            {"id": "MAINTENANCE", "title": "Bakımda"},
            {"id": "OTHER", "title": "Diğer"},
        ]
    }
);