import {EnumStore} from "./base/EnumStore";

export const TaskScopeStore = new EnumStore({
        key: "id",
        data: [
            {"id": "CUSTOMER", "title": "Müşteri"},
            {"id": "VENDING", "title": "Otomat"},
            {"id": "VEHICLE", "title": "Araç"},
            {"id": "WAREHOUSE", "title": "Depo"}
        ]
    }
);