import Form, {GroupItem, Item, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import {useEffect, useRef, useState} from "react";
import {Customer} from "../../../domain/Customer";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {WeekdaysStore} from "../../../datastore/WeekdaysStore";
import {CustomerStore} from "../../../datastore/CustomerStore";
import {TechnicianStore} from "../../../datastore/TechnicianStore";
import {isFormValid} from "../../../utils/utils";
import {confirm} from "devextreme/ui/dialog";
import {RegionStore} from "../../../datastore/RegionStore";

export function CustomersEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const [record, setRecord] = useState<Customer>();
    const {id} = useParams();
    const isNewRecord = !id;

    useEffect(() => {
        if (!isNewRecord) {
            CustomerStore.byKey(Number(id)).then((data) => {
                setRecord(data);
            });
        } else {
            setRecord({});
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/customers")
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (isNewRecord) {
            CustomerStore.insert(record!).then(cancelClick);
        } else {
            CustomerStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`<b>${record?.title}</b> adlı müşteriyi silmek istiyor musunuz?`, "Müşteri Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    CustomerStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni Müşteri" : "Müşteri Güncelle"}/>
            <Form ref={formRef}
                  formData={record}
                  onContentReady={(e) => {
                      e.component.getEditor("title")?.focus();
                  }}
            >
                <SimpleItem dataField="title" editorType="dxTextBox" editorOptions={{maxLength: 100}} label={{text: "Müşteri Adı"}}>
                    <RequiredRule message="Lütfen müşteri adı giriniz"/>
                </SimpleItem>
                <Item dataField="region" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: RegionStore,
                          displayExpr: 'title',
                          searchEnabled: true
                      }}>
                    <Label text="Bölge"/>
                </Item>
                <SimpleItem dataField="address" editorType="dxTextBox" editorOptions={{maxLength: 100}} label={{text: "Adres"}}>
                </SimpleItem>
                <Item dataField="technician" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: TechnicianStore,
                          displayExpr: 'name',
                          searchEnabled: true
                      }}>
                    <Label text="Teknisyen"/>
                </Item>
                <Item dataField="visits" editorType="dxTagBox"
                      editorOptions={{
                          dataSource: WeekdaysStore,
                          showSelectionControls: true,
                          displayExpr: 'title',
                          valueExpr: 'id'
                      }}>
                    <Label text="Ziyaret Günleri"/>
                </Item>

                <GroupItem caption="Finans" colCount={3}>
                    <SimpleItem dataField="currentAccountCode" editorType="dxTextBox" editorOptions={{maxLength: 50}}><Label text="Cari Kodu"/></SimpleItem>
                    <SimpleItem dataField="taxAdministration" editorType="dxTextBox" editorOptions={{maxLength: 100}} label={{text: "Vergi Dairesi"}}/>
                    <SimpleItem dataField="taxNumber" editorType="dxTextBox" editorOptions={{maxLength: 20}} label={{text: "Vergi Numarası"}}/>
                </GroupItem>

                <GroupItem caption="Kodlar" colCount={3}>
                    <SimpleItem dataField="dmsCode" editorType="dxTextBox" editorOptions={{maxLength: 50}}><Label text="DMS Kodu"/></SimpleItem>
                    <SimpleItem dataField="paymentTicketAccountCode" editorType="dxTextBox" editorOptions={{maxLength: 50}}><Label text="Ticket Üye Kodu"/></SimpleItem>
                    <SimpleItem dataField="paymentTicketTerminalId" editorType="dxTextBox" editorOptions={{maxLength: 50}}><Label text="Ticket Terminal Id"/></SimpleItem>
                </GroupItem>
            </Form>
            <Toolbar>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>
        </>
    )
}