import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {Column as GridColumn, DataGrid} from "devextreme-react/data-grid";
import {Button} from "devextreme-react";
import {Vehicle} from "../../../domain/Vehicle";
import {VehicleInventoriesStore, VehicleStore} from "../../../datastore/VehicleStore";

export function VehicleInventoriesState() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [record, setRecord] = useState<Vehicle>();

    const listClick = () => {
        navigate(`/vehicles/inventories`);
    }

    useEffect(() => {
        VehicleStore.byKey(Number(id)).then((data) => {
            setRecord(data);
        });
    }, [id]);

    return (
        <>
            <Toolbar>
                <ToolbarItem location="before">
                    <Button text="ARAÇ DEPOLARI" onClick={listClick} icon="undo"/>
                </ToolbarItem>
            </Toolbar>

            <FormHeaderToolbar header={`Araç Deposu Ürünleri - ${record?.licensePlate}`}/>
            <DataGrid
                dataSource={VehicleInventoriesStore(Number(id))}
                showBorders={true}
                hoverStateEnabled={true}
                rowAlternationEnabled={true}
            >
                <GridColumn dataField="product.brand.title" caption="Marka Adı"/>
                <GridColumn dataField="product.title" caption="Ürün Adı"/>
                <GridColumn dataField="quantity" caption="Miktar" format=",##0" width="150"/>
            </DataGrid>
        </>
    )
}