import {EnumStore} from "./base/EnumStore";

export const VendingTypeStore = new EnumStore({
        key: "id",
        data: [
            {"id": "HOT_DRINK", "title": "Sıcak İçecek"},
            {"id": "SNACK", "title": "Snack"},
            {"id": "COLD_DRINK", "title": "Soğuk İçecek"},
        ]
    }
);