import {Role} from "../domain/Role";
import axios, {AxiosResponse} from "axios";

export interface CurrentUser {
    id?: number,
    email?: string,
    username?: string,
    firstName?: string,
    lastName?: string,
    role?: Role
}

export interface LoginResponse {
    token: string,
    expiration: Date,
    user: CurrentUser
}

export class AuthStore {
    me(): Promise<AxiosResponse<CurrentUser>> {
        return axios.get('/api/auth/me')
    }

    login(username: string, password: string): Promise<AxiosResponse<LoginResponse>> {
        return axios.post('/api/auth/login', {
            username: username,
            password: password
        })
    }
}