import Form, {Item, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import {useEffect, useRef, useState} from "react";
import {HandheldTerminal} from "../../../domain/HandheldTerminal";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {HandheldTerminalStateStore} from "../../../datastore/HandheldTerminalStateStore";
import {HandheldTerminalStore} from "../../../datastore/HandheldTerminalStore";
import {isFormValid} from "../../../utils/utils";
import {confirm} from "devextreme/ui/dialog";

export function HandheldTerminalsEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const [record, setRecord] = useState<HandheldTerminal>();
    const {id} = useParams();
    const isNewRecord = !id;

    useEffect(() => {
        if (!isNewRecord) {
            HandheldTerminalStore.byKey(Number(id)).then((data) => {
                setRecord(data);
            });
        } else {
            setRecord({});
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/handheld-terminals")
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (isNewRecord) {
            HandheldTerminalStore.insert(record!).then(cancelClick);
        } else {
            HandheldTerminalStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`<b>${record?.stockCode}</b> kodlu el terminalini silmek istiyor musunuz?`, "El Terminali Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    HandheldTerminalStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni El Terminali" : "El Terminali Güncelle"}/>
            <Form ref={formRef}
                  formData={record}
                  onContentReady={(e) => {
                      e.component.getEditor("stockCode")?.focus();
                  }}
            >
                <SimpleItem dataField="stockCode" editorType="dxTextBox" label={{text: "Stok Kodu"}}>
                    <RequiredRule message="Lütfen stok kodu giriniz"/>
                </SimpleItem>
                <SimpleItem dataField="serialNumber" editorType="dxTextBox" label={{text: "Seri No"}}>
                    <RequiredRule message="Lütfen seri no giriniz"/>
                </SimpleItem>
                <SimpleItem dataField="model" editorType="dxTextBox" label={{text: "Model"}}/>
                <SimpleItem dataField="gsmNumber" editorType="dxTextBox" label={{text: "GSM"}}/>
                <SimpleItem dataField="purchaseDate" editorType="dxDateBox" label={{text: "Alış Tarihi"}}
                            editorOptions={{openOnFieldClick: true}}/>
                {!isNewRecord &&
                    <Item dataField="state" editorType="dxSelectBox"
                          editorOptions={{
                              dataSource: HandheldTerminalStateStore,
                              displayExpr: 'title',
                              valueExpr: 'id',
                              readOnly: true
                          }}>
                        <Label text="Durum"/>
                    </Item>
                }
            </Form>
            <Toolbar>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>
        </>
    )
}