import {Column} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import React from "react";
import {ProductSaleStore} from "../../../datastore/ProductSaleStore";
import {VendingTypeStore} from "../../../datastore/VendingTypeStore";
import {ProductSale} from "../../../domain/ProductSale";

export function ProductSalesList() {
    return (
        <>
            <BaseDataGridList
                dataSource={ProductSaleStore}
                header="Ürün Satışları"
            >
                <Column dataField="transactionAt" caption="Satış Zamanı" dataType="datetime" allowFiltering={false}/>
                <Column dataField="receivedAt" caption="DMS Zamanı" dataType="datetime" allowFiltering={false}/>
                <Column dataField="createdAt" caption="Kayıt Zamanı" dataType="datetime" allowFiltering={false}/>
                <Column dataField="customerVending.customer.title" caption="Müşteri"/>
                <Column dataField="vendingStockCode" caption="Otomat" width="100"/>
                <Column dataField="vending.model.title" caption="Otomat Modeli"/>
                <Column dataField="vending.model.type" caption="Otomat Cinsi"
                        calculateDisplayValue={(data: ProductSale) => VendingTypeStore.findByKey(data?.vending?.model?.type)?.title}
                        calculateCellValue={(data: ProductSale) => VendingTypeStore.findByKey(data?.vending?.model?.type)?.title}
                        lookup={
                            {
                                dataSource: VendingTypeStore,
                                valueExpr: "id",
                                displayExpr: "title",
                                allowClearing: true
                            }
                        }
                />
                <Column dataField="transactionType" caption="İşlem Tipi"/>
                <Column dataField="providerOptionNumber" caption="DMS Sıra No" allowFiltering={false} width="100"/>
                <Column dataField="optionNumber" caption="Sıra No" allowFiltering={false} width="100"/>
                <Column dataField="amount" caption="Tutar" format=",##0.## TL" allowFiltering={false} width="100"/>
            </BaseDataGridList>
        </>
    )
}
