import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {Button, DateBox, Popup, Toolbar} from "devextreme-react";
import React, {useEffect, useState} from "react";
import {Order} from "../../../domain/Order";
import {OrderStore} from "../../../datastore/OrderStore";
import {Item as ToolbarItem} from "devextreme-react/toolbar";
import Form, {GroupItem, Item, Label, SimpleItem} from "devextreme-react/form";
import {SupplierStore} from "../../../datastore/SupplierStore";
import {Column as GridColumn, DataGrid, Lookup, Summary, TotalItem} from "devextreme-react/data-grid";
import {ProductUnitStore} from "../../../datastore/ProductUnitStore";
import {alert, confirm} from "devextreme/ui/dialog";
import {WarehouseStore} from "../../../datastore/WarehouseStore";
import {moneyFormat} from "../../../utils/money";


export function OrdersState() {
    const navigate = useNavigate();
    const [record, setRecord] = useState<Order>();
    const {id} = useParams();
    const [completeFormVisible, setCompleteFormVisible] = useState(false);
    const [deliveredAt, setDeliveredAt] = useState<Date>();

    useEffect(() => {
        OrderStore.byKey(Number(id)).then((data) => {
            setRecord(data);
        });
    }, [id]);

    const goBack = () => {
        navigate(`/orders`);
    }

    const editClick = () => {
        navigate(`/orders/${id}`);
    }
    const cancelClick = () => {
        confirm("Siparişi iptal etmek istiyor musunuz?", "Sipariş İptal")
            .then((dialogResult) => {
                if (dialogResult) {
                    OrderStore.cancel(Number(id))
                        .then(() => {
                            alert("Sipariş iptal edildi.", "Sipariş İptal")
                                .then(() => {
                                    goBack();
                                })
                        });
                }
            });
    }

    const completeClick = () => {
        setCompleteFormVisible(true);
    }
    const waitingClick = () => {
        confirm("Siparişi beklemeye almak istiyor musunuz?", "Siparişi Beklemeye Al")
            .then((dialogResult) => {
                if (dialogResult) {
                    OrderStore.waiting(Number(id))
                        .then(() => {
                            alert("Sipariş beklemeye alındı.", "Siparişi Beklemeye Al")
                                .then(() => {
                                    goBack();
                                })
                        });
                }
            });
    }

    const completeFormCancelClick = () => {
        setCompleteFormVisible(false);
    }

    const completeFormSaveClick = () => {
        confirm("Siparişi tamamlamak istiyor musunuz?", "Siparişi Tamamla")
            .then((dialogResult) => {
                if (dialogResult) {
                    // get warehouses
                    WarehouseStore.load()
                        .then((result: any) => {
                            // TODO select warehouse if multiple
                            if (result.totalCount === 0) {
                                alert("Depo bulunamadı. Lütfen depo ekleyiniz", "Hata")
                                    .then(() => {});
                                return;
                            } else {
                                OrderStore.complete(Number(id), result.data[0].id, deliveredAt)
                                    .then(() => {
                                        alert("Sipariş tamamlandı, tüm ürünler depoya eklendi.", "Siparişi Tamamla")
                                            .then(() => {
                                                goBack();
                                            })
                                    });
                            }

                        });

                }
            });
    }

    return (
        <>
            <Toolbar>
                <ToolbarItem location="before">
                    <Button text="SİPARİŞ LİSTESİ" onClick={goBack} icon="undo"/>
                </ToolbarItem>
                <ToolbarItem location="before">
                    <Button text="SİPARİŞ GÜNCELLE" onClick={editClick} icon="edit"/>
                </ToolbarItem>
                <ToolbarItem location="before">
                    <Button text="SİPARİŞİ TAMAMLA" onClick={completeClick} icon="check"
                            disabled={!(record?.state === "WAITING")}/>
                </ToolbarItem>
                <ToolbarItem location="before">
                    <Button text="SİPARİŞİ BEKLEMEYE AL" onClick={waitingClick} icon="redo"
                            disabled={!(record?.state !== "WAITING")}/>
                </ToolbarItem>
                <ToolbarItem location="before">
                    <Button text="SİPARİŞİ İPTAL ET" onClick={cancelClick} icon="clear"
                            disabled={!(record?.state !== "CANCELLED")}/>
                </ToolbarItem>
            </Toolbar>


            <Form formData={record} readOnly={true}
            >
                <GroupItem colCount={3}>
                    <SimpleItem dataField="id" editorType="dxTextBox" label={{text: "Sipariş No"}}
                                editorOptions={{readOnly: true}}/>
                    <SimpleItem dataField="invoiceNumber" editorType="dxTextBox" label={{text: "Fatura No"}}/>
                    <Item dataField="supplier" editorType="dxSelectBox"
                          editorOptions={{
                              dataSource: SupplierStore,
                              searchEnabled: true,
                              displayExpr: 'title'
                          }}>
                        <Label text="Tedarikçi"/>
                    </Item>
                </GroupItem>
                <GroupItem colCount={2}>
                    <SimpleItem dataField="orderedAt" editorType="dxDateBox" label={{text: "Sipariş Tarihi"}}
                                editorOptions={{openOnFieldClick: true}}/>
                    <SimpleItem dataField="deliveredAt" editorType="dxDateBox" label={{text: "Teslim Tarihi & Saati"}}
                                editorOptions={
                                    {
                                        dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
                                        type: 'datetime',
                                        openOnFieldClick: true
                                    }
                                }/>
                </GroupItem>

                <GroupItem caption="Ürünler">
                    <DataGrid
                        dataSource={record?.products}
                        showBorders={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        height={300}
                    >
                        <GridColumn dataField="product.brand.title" caption="Marka Adı"/>
                        <GridColumn dataField="product.title" caption="Ürün Adı"/>
                        <GridColumn dataField="price" caption="Birim Fiyat" format="#0.## TL" width="100"/>
                        <GridColumn dataField="quantity" caption="Miktar" format="#0.##" width="80"/>
                        <GridColumn dataField="unit" caption="Birim" width="100">
                            <Lookup dataSource={ProductUnitStore} valueExpr="id" displayExpr="title"/>
                        </GridColumn>
                        <GridColumn dataField="amount" caption="Tutar" format=",##0.## TL" width="120"/>
                        <GridColumn dataField="taxRate" caption="KDV" format="% #" width="60"/>
                        <GridColumn dataField="taxAmount" caption="KDV Tutarı" format=",##0.## TL" width="120"/>
                        <GridColumn dataField="totalAmount" caption="Toplam Tutar" format=",##0.## TL" width="120"/>
                        <Summary>
                            <TotalItem
                                customizeText={(d) => moneyFormat(d.value)}
                                column="amount"
                                summaryType="sum"
                            />
                            <TotalItem
                                customizeText={(d) => moneyFormat(d.value)}
                                column="taxAmount"
                                summaryType="sum"
                            />
                            <TotalItem
                                customizeText={(d) => moneyFormat(d.value)}
                                column="totalAmount"
                                summaryType="sum"
                            />
                        </Summary>
                    </DataGrid>
                </GroupItem>
            </Form>

            <Popup
                visible={completeFormVisible}
                showCloseButton={true}
                onHiding={completeFormCancelClick}
                title="Sipariş Tamamla"
                width={400}
                height={250}
            >
                <DateBox label="Teslim Tarihi & Saati"
                         type="datetime"
                         openOnFieldClick={true}
                         value={deliveredAt}
                         onValueChanged={({value}) => setDeliveredAt(value)}
                />

                <Toolbar>
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "İptal", icon: "back", onClick: completeFormCancelClick}}/>
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Siparişi Tamamla", icon: "save", onClick: completeFormSaveClick}}/>
                </Toolbar>
            </Popup>
        </>
    )
}