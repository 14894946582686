import {
    Column,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {ReasonForReturnStore} from "../../../datastore/ReasonForReturnStore";

export function ReasonForReturnsList() {
    return (
        <BaseDataGridList
            dataSource={ReasonForReturnStore}
            header="İade Nedenleri"
            editHref="/reason-for-returns"
        >
            <Column dataField="title" caption="İade Nedeni Adı"/>
        </BaseDataGridList>
    )
}
