import {HomePage, ProfilePage, TasksPage} from './pages';
import {withNavigationWatcher} from './contexts/navigation';
import {CreateAccountForm} from "./components";
import {BrandsList} from "./pages/master-data/brands/BrandsList";
import {BrandsEdit} from "./pages/master-data/brands/BrandsEdit";
import {ProductsList} from "./pages/master-data/products/ProductsList";
import {ProductsEdit} from "./pages/master-data/products/ProductsEdit";
import {HotDrinksEdit} from "./pages/master-data/hot-drinks/HotDrinksEdit";
import {HotDrinksList} from "./pages/master-data/hot-drinks/HotDrinksList";
import {OperatingSystemsList} from "./pages/master-data/operating-systems/OperatingSystemsList";
import {OperatingSystemsEdit} from "./pages/master-data/operating-systems/OperatingSystemsEdit";
import {PaymentSystemsList} from "./pages/master-data/payment-systems/PaymentSystemsList";
import {PaymentSystemsEdit} from "./pages/master-data/payment-systems/PaymentSystemsEdit";
import {VendingModelsList} from "./pages/master-data/vending-models/VendingModelsList";
import {VendingModelsEdit} from "./pages/master-data/vending-models/VendingModelsEdit";
import {VendingImagesList} from "./pages/master-data/vending-images/VendingImagesList";
import {VendingImagesEdit} from "./pages/master-data/vending-images/VendingImagesEdit";
import {SuppliersList} from "./pages/master-data/suppliers/SuppliersList";
import {SuppliersEdit} from "./pages/master-data/suppliers/SuppliersEdit";
import {VehiclesList} from "./pages/master-data/vehicles/VehiclesList";
import {VehiclesEdit} from "./pages/master-data/vehicles/VehiclesEdit";
import {ReasonForReturnsList} from "./pages/master-data/reason-for-returns/ReasonForReturnsList";
import {ReasonForReturnsEdit} from "./pages/master-data/reason-for-returns/ReasonForReturnsEdit";
import {HandheldTerminalsList} from "./pages/master-data/handheld-terminals/HandheldTerminalsList";
import {HandheldTerminalsEdit} from "./pages/master-data/handheld-terminals/HandheldTerminalsEdit";
import {CustomersList} from "./pages/operation/customers/CustomersList";
import {CustomersEdit} from "./pages/operation/customers/CustomersEdit";
import {VendingsList} from "./pages/operation/vendings/VendingsList";
import {VendingsEdit} from "./pages/operation/vendings/VendingsEdit";
import {TechniciansList} from "./pages/employee/technician/TechniciansList";
import {TechniciansEdit} from "./pages/employee/technician/TechniciansEdit";
import {OrdersList} from "./pages/operation/orders/OrdersList";
import {OrdersEdit} from "./pages/operation/orders/OrdersEdit";
import {OrdersState} from "./pages/operation/orders/OrdersState";
import {WarehousesList} from "./pages/inventories/warehouses/WarehousesList";
import {WarehousesEdit} from "./pages/inventories/warehouses/WarehousesEdit";
import {WarehousesState} from "./pages/inventories/warehouses/WarehousesState";
import {CustomerVendingsList} from "./pages/operation/customer-vendings/CustomerVendingsList";
import {CustomerVendingsEdit} from "./pages/operation/customer-vendings/CustomerVendingsEdit";
import {CustomerVendingsState} from "./pages/operation/customer-vendings/CustomerVendingsState";
import {CustomerVendingVersionsEdit} from "./pages/operation/customer-vending-versions/CustomerVendingVersionsEdit";
import {PaymentDevicesList} from "./pages/inventories/payment-devices/PaymentDevicesList";
import {PaymentDevicesEdit} from "./pages/inventories/payment-devices/PaymentDevicesEdit";
import {RegionsList} from "./pages/master-data/regions/RegionsList";
import {RegionsEdit} from "./pages/master-data/regions/RegionsEdit";
import {TaskDefinitionsEdit} from "./pages/master-data/task-definitions/TaskDefinitionsEdit";
import {TaskDefinitionsList} from "./pages/master-data/task-definitions/TaskDefinitionsList";
import {TechnicianTasksList} from "./pages/operation/technician-tasks/TechnicianTasksList";
import {TechnicianTasksEdit} from "./pages/operation/technician-tasks/TechnicianTasksEdit";
import {TechnicianHandheldsList} from "./pages/master-data/technician-handhelds/TechnicianHandheldsList";
import {TechnicianHandheldsEdit} from "./pages/master-data/technician-handhelds/TechnicianHandheldsEdit";
import {TechnicianVehiclesList} from "./pages/master-data/technician-vehicles/TechnicianVehiclesList";
import {TechnicianVehiclesEdit} from "./pages/master-data/technician-vehicles/TechnicianVehiclesEdit";
import {VehicleInventoriesState} from "./pages/inventories/vehicle-inventories/VehicleInventoriesState";
import {VehicleInventoriesList} from "./pages/inventories/vehicle-inventories/VehicleInventoriesList";
import {InventoryTransactionList} from "./pages/operation/inventory-transactions/InventoryTransactionList";
import {InventoryTransactionEdit} from "./pages/operation/inventory-transactions/InventoryTransactionEdit";
import {TransportTypesList} from "./pages/master-data/transport-types/TransportTypesList";
import {TransportTypesEdit} from "./pages/master-data/transport-types/TransportTypesEdit";
import {ProductSalesList} from "./pages/operation/product-sales/ProductSalesList";
import {CustomerVendingVisitsList} from "./pages/operation/customer-vending-visits/CustomerVendingVisitsList";
import {CustomerVendingLoadsList} from "./pages/operation/customer-vending-loads/CustomerVendingLoadsList";
import {TechnicianOrdersList} from "./pages/operation/technician-orders/TechnicianOrdersList";
import {TechnicianOrdersEdit} from "./pages/operation/technician-orders/TechnicianOrdersEdit";
import {CustomerVendingUnloadsList} from "./pages/operation/customer-vending-unloads/CustomerVendingUnloadsList";
import {CustomerVendingLoadsView} from "./pages/operation/customer-vending-loads/CustomerVendingLoadsView";
import {CustomerVendingUnloadsView} from "./pages/operation/customer-vending-unloads/CustomerVendingUnloadsView";

const routes = [
    {path: '/tasks', element: TasksPage},
    {path: '/profile', element: ProfilePage},
    {path: '/home', element: HomePage},
    {path: '/brands', element: BrandsList},
    {path: '/brands/new', element: BrandsEdit},
    {path: '/brands/:id', element: BrandsEdit},
    {path: '/products', element: ProductsList},
    {path: '/products/new', element: ProductsEdit},
    {path: '/products/:id', element: ProductsEdit},
    {path: '/hot-drinks', element: HotDrinksList},
    {path: '/hot-drinks/new', element: HotDrinksEdit},
    {path: '/hot-drinks/:id', element: HotDrinksEdit},
    {path: '/operating-systems', element: OperatingSystemsList},
    {path: '/operating-systems/new', element: OperatingSystemsEdit},
    {path: '/operating-systems/:id', element: OperatingSystemsEdit},
    {path: '/payment-systems', element: PaymentSystemsList},
    {path: '/payment-systems/new', element: PaymentSystemsEdit},
    {path: '/payment-systems/:id', element: PaymentSystemsEdit},
    {path: '/vending-models', element: VendingModelsList},
    {path: '/vending-models/new', element: VendingModelsEdit},
    {path: '/vending-models/:id', element: VendingModelsEdit},
    {path: '/vending-images', element: VendingImagesList},
    {path: '/vending-images/new', element: VendingImagesEdit},
    {path: '/vending-images/:id', element: VendingImagesEdit},
    {path: '/suppliers', element: SuppliersList},
    {path: '/suppliers/new', element: SuppliersEdit},
    {path: '/suppliers/:id', element: SuppliersEdit},
    {path: '/vehicles', element: VehiclesList},
    {path: '/vehicles/new', element: VehiclesEdit},
    {path: '/vehicles/:id', element: VehiclesEdit},
    {path: '/reason-for-returns', element: ReasonForReturnsList},
    {path: '/reason-for-returns/new', element: ReasonForReturnsEdit},
    {path: '/reason-for-returns/:id', element: ReasonForReturnsEdit},
    {path: '/handheld-terminals', element: HandheldTerminalsList},
    {path: '/handheld-terminals/new', element: HandheldTerminalsEdit},
    {path: '/handheld-terminals/:id', element: HandheldTerminalsEdit},
    {path: '/regions', element: RegionsList},
    {path: '/regions/new', element: RegionsEdit},
    {path: '/regions/:id', element: RegionsEdit},
    {path: '/customers', element: CustomersList},
    {path: '/customers/new', element: CustomersEdit},
    {path: '/customers/:id', element: CustomersEdit},
    {path: '/vendings', element: VendingsList},
    {path: '/vendings/new', element: VendingsEdit},
    {path: '/vendings/:id', element: VendingsEdit},
    {path: '/technicians', element: TechniciansList},
    {path: '/technicians/new', element: TechniciansEdit},
    {path: '/technicians/:id', element: TechniciansEdit},
    {path: '/orders', element: OrdersList},
    {path: '/orders/new', element: OrdersEdit},
    {path: '/orders/:id', element: OrdersEdit},
    {path: '/orders/:id/state', element: OrdersState},
    {path: '/warehouses', element: WarehousesList},
    {path: '/warehouses/new', element: WarehousesEdit},
    {path: '/warehouses/:id', element: WarehousesEdit},
    {path: '/warehouses/:id/state', element: WarehousesState},
    {path: '/customer-vendings', element: CustomerVendingsList},
    {path: '/customer-vendings/new', element: CustomerVendingsEdit},
    {path: '/customer-vendings/:id', element: CustomerVendingsEdit},
    {path: '/customer-vendings/:id/state', element: CustomerVendingsState},
    {path: '/customer-vendings/:customerVendingId/versions/new', element: CustomerVendingVersionsEdit},
    {path: '/customer-vendings/:customerVendingId/versions/:id', element: CustomerVendingVersionsEdit},
    {path: '/payment-devices', element: PaymentDevicesList},
    {path: '/payment-devices/new', element: PaymentDevicesEdit},
    {path: '/payment-devices/:id', element: PaymentDevicesEdit},
    {path: '/task-definitions', element: TaskDefinitionsList},
    {path: '/task-definitions/new', element: TaskDefinitionsEdit},
    {path: '/task-definitions/:id', element: TaskDefinitionsEdit},
    {path: '/technician-tasks', element: TechnicianTasksList},
    {path: '/technician-tasks/new', element: TechnicianTasksEdit},
    {path: '/technician-tasks/:id', element: TechnicianTasksEdit},
    {path: '/technician-handhelds', element: TechnicianHandheldsList},
    {path: '/technician-handhelds/new', element: TechnicianHandheldsEdit},
    {path: '/technician-handhelds/:id', element: TechnicianHandheldsEdit},
    {path: '/technician-vehicles', element: TechnicianVehiclesList},
    {path: '/technician-vehicles/new', element: TechnicianVehiclesEdit},
    {path: '/technician-vehicles/:id', element: TechnicianVehiclesEdit},
    {path: '/vehicles/inventories', element: VehicleInventoriesList},
    {path: '/vehicles/inventories/:id', element: VehicleInventoriesState},
    {path: '/inventory-transactions', element: InventoryTransactionList},
    {path: '/inventory-transactions/new', element: InventoryTransactionEdit},
    {path: '/inventory-transactions/:id', element: InventoryTransactionEdit},
    {path: '/transport-types', element: TransportTypesList},
    {path: '/transport-types/new', element: TransportTypesEdit},
    {path: '/transport-types/:id', element: TransportTypesEdit},
    {path: '/product-sales', element: ProductSalesList},
    {path: '/customer-vending-visits', element: CustomerVendingVisitsList},
    {path: '/customer-vending-loads', element: CustomerVendingLoadsList},
    {path: '/customer-vending-loads/:id', element: CustomerVendingLoadsView},
    {path: '/customer-vending-unloads', element: CustomerVendingUnloadsList},
    {path: '/customer-vending-unloads/:id', element: CustomerVendingUnloadsView},
    {path: '/technician-orders', element: TechnicianOrdersList},
    {path: '/technician-orders/new', element: TechnicianOrdersEdit},
    {path: '/technician-orders/:id', element: TechnicianOrdersEdit},

    {path: '/test', element: CreateAccountForm}
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
