import {Column, Lookup,} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {VendingTypeStore} from "../../../datastore/VendingTypeStore";
import {Vending} from "../../../domain/Vending";
import {CustomerVendingStateStore} from "../../../datastore/CustomerVendingStateStore";
import {CustomerVendingStore} from "../../../datastore/CustomerVendingStore";
import {CustomerVending} from "../../../domain/CustomerVending";

export function CustomerVendingsList() {
    return (
        <BaseDataGridList
            dataSource={CustomerVendingStore}
            header="Müşteri Otomatları"
            editHref="/customer-vendings"
            selectedHrefSuffix="/state"
        >
            <Column dataField="customer.title" caption="Müşteri" allowResizing={true}/>
            <Column dataField="customer.technician.name" caption="Teknisyen" allowResizing={true}/>
            <Column dataField="vending.stockCode" caption="Barkod" allowResizing={true}/>
            <Column dataField="vending.chassisCode" caption="Şase Kodu" allowResizing={true}/>
            <Column dataField="vending.model.title" caption="Otomat Modeli" allowResizing={true}/>
            <Column dataField="vending.model.type" caption="Otomat Cinsi"
                    calculateDisplayValue={(data: CustomerVending) => VendingTypeStore.findByKey(data?.vending?.model?.type)?.title}
                    calculateCellValue={(data: CustomerVending) => VendingTypeStore.findByKey(data?.vending?.model?.type)?.title}
                    lookup={
                        {
                            dataSource: VendingTypeStore,
                            valueExpr: "id",
                            displayExpr: "title",
                            allowClearing: true
                        }
                    }
                    allowResizing={true}
            >
            </Column>
            <Column dataField="state" caption="Durum"
                    calculateDisplayValue={(data: Vending) => CustomerVendingStateStore.findByKey(data.state)?.title}
                    calculateCellValue={(data: Vending) => CustomerVendingStateStore.findByKey(data.state)?.title}
                    allowResizing={true}
                >
                <Lookup dataSource={CustomerVendingStateStore} valueExpr="id" displayExpr="title"/>
            </Column>
        </BaseDataGridList>
    )
}
