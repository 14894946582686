import {
    Column,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {TechnicianHandheldStore} from "../../../datastore/TechnicianHandheldStore";
import {TechnicianHandheldTerminalStateStore} from "../../../datastore/TechnicianHandheldTerminalStateStore";
import {TechnicianHandheld} from "../../../domain/TechnicianHandheld";

export function TechnicianHandheldsList() {
    return (
        <BaseDataGridList
            dataSource={TechnicianHandheldStore}
            header="Teknisyen Atamaları"
            editHref="/technician-handhelds"
        >
            <Column dataField="technician.personalCode" caption="Personel Kodu"/>
            <Column dataField="technician.name" caption="Teknisyen Adı"/>
            <Column dataField="handheldTerminal.stockCode" caption="El Terminali Kodu"/>
            <Column dataField="handheldTerminal.serialNumber" caption="El Terminali Seri Numarası"/>
            <Column dataField="handheldTerminal.model" caption="El Terminali Modeli"/>
            <Column dataField="state" caption="Durum"
                    calculateDisplayValue={(data: TechnicianHandheld) => TechnicianHandheldTerminalStateStore.findByKey(data?.state)?.title}
                    calculateCellValue={(data: TechnicianHandheld) => TechnicianHandheldTerminalStateStore.findByKey(data?.state)?.title}
                    lookup={
                        {
                            dataSource: TechnicianHandheldTerminalStateStore,
                            valueExpr: "id",
                            displayExpr: "title",
                            allowClearing: true
                        }
                    }
            />
        </BaseDataGridList>
    )
}
