import {EnumStore} from "./base/EnumStore";

export const CustomerVendingVersionStateStore = new EnumStore({
        key: "id",
        data: [
            {"id": "DRAFT", "title": "Taslak"},
            {"id": "ACTIVE", "title": "Aktif"},
            {"id": "ARCHIVE", "title": "Arşiv"}
        ]
    }
);