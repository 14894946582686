import {
    Column, Lookup,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {ProductCategoriesStore} from "../../../datastore/ProductCategoriesStore";
import {ProductStore} from "../../../datastore/ProductStore";

export function ProductsList() {
    return (
        <BaseDataGridList
            dataSource={ProductStore}
            header="Ürünler"
            editHref="/products"
        >
            <Column dataField="title" caption="Ürün Adı"/>
            <Column dataField="brand.title" caption="Marka Adı"/>
            <Column dataField="category" caption="Kategori">
                <Lookup dataSource={ProductCategoriesStore} valueExpr="id" displayExpr="title"/>
            </Column>
        </BaseDataGridList>
    )
}
