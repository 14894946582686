import Box, {Item} from "devextreme-react/box";
import {DashboardCard} from "../../../layouts/dashboard-card/DashboardCard";
import {useEffect, useState} from "react";
import {StatisticsSales, StatisticsStore} from "../../../datastore/StatisticsStore";
import {moneyFormat} from "../../../utils/money";

export function ProductSaleIndicator() {
    const [sales, setSales] = useState<StatisticsSales>();
    useEffect(() => {
        StatisticsStore.sales()
            .then(s => {
                console.log(s.data);
                setSales(s.data);
            });
    }, [])

    return (
        <>
            <Box direction="row" width="100%">
                <Item ratio={1}>
                    <DashboardCard title="Günlük" titleStyle={{align: 'center'}}>
                        <div className="visited-indicator">
                            <div className="text">{sales ? moneyFormat(sales.today): "-"}</div>
                        </div>
                    </DashboardCard>
                </Item>
                <Item ratio={1}>
                    <DashboardCard title="Haftalık" titleStyle={{align: 'center'}}>
                        <div className="visited-indicator">
                            <div className="text">{sales ? moneyFormat(sales.week): "-"}</div>
                        </div>
                    </DashboardCard>
                </Item>
                <Item ratio={1}>
                    <DashboardCard title="Aylık" titleStyle={{align: 'center'}}>
                        <div className="visited-indicator">
                            <div className="text">{sales ? moneyFormat(sales.month): "-"}</div>
                        </div>
                    </DashboardCard>
                </Item>
            </Box>
        </>
    )
}