import Form, {GroupItem, Item, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import React, {useEffect, useRef, useState} from "react";
import {VendingModel, VendingModelHotDrink} from "../../../domain/VendingModel";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {VendingModelStore} from "../../../datastore/VendingModelStore";
import {isFormValid} from "../../../utils/utils";
import {alert, confirm} from "devextreme/ui/dialog";
import {VendingTypeStore} from "../../../datastore/VendingTypeStore";
import {
    Column as GridColumn,
    DataGrid,
    Editing as GridEditing,
    Item as GridItem,
    Selection as GridSelection,
    Toolbar as GridToolbar
} from "devextreme-react/data-grid";
import {SelectBox} from "devextreme-react";
import {HotDrinkStore} from "../../../datastore/HotDrinkStore";
import {HotDrink} from "../../../domain/HotDrink";

export function VendingModelsEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const hotDrinksGridRef = useRef<DataGrid>(null);
    const optionNumberMatchesGridRef = useRef<DataGrid>(null);
    const [hotDrinksVisible, setHotDrinksVisible] = useState(false);
    const [record, setRecord] = useState<VendingModel>();
    const {id} = useParams();
    const isNewRecord = !id;

    useEffect(() => {
        if (!isNewRecord) {
            VendingModelStore.byKey(Number(id)).then((data) => {
                data.hotDrinks = data.hotDrinks || [];
                data.optionNumberMatches = data.optionNumberMatches || [];
                setRecord(data);
                setHotDrinksVisible(data?.type === "HOT_DRINK");
            });
        } else {
            setRecord({
                hotDrinks: [],
                optionNumberMatches: []
            });
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/vending-models")
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (optionNumberMatchesGridRef.current?.instance.hasEditData()) {
            alert("Makine sıra numarası eşleştirme listesinde tamamlanmamış değişiklik bulunmaktadır. Lütfen ilgili değişikliği kaydedin veya iptal edin", "Hata");
            return;
        }
        if (hotDrinksGridRef.current?.instance.hasEditData()) {
            alert("Sıcak menüsü listesinde tamamlanmamış değişiklik bulunmaktadır. Lütfen ilgili değişikliği kaydedin veya iptal edin", "Hata");
            return;
        }
        if (isNewRecord) {
            VendingModelStore.insert(record!).then(cancelClick);
        } else {
            VendingModelStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`<b>${record?.title}</b> adlı otomat modelini silmek istiyor musunuz?`, "Otomat Modeli Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    VendingModelStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni Otomat Modeli" : "Otomat Modeli Güncelle"}/>
            <Form ref={formRef}
                  formData={record}
                  colCount={2}
                  onContentReady={(e) => {
                      e.component.getEditor("title")?.focus();
                  }}
                  onFieldDataChanged={(e) => {
                      setHotDrinksVisible(record?.type === "HOT_DRINK");
                  }}
            >
                <SimpleItem dataField="title" editorType="dxTextBox" label={{text: "Otomat Modeli Adı"}}>
                    <RequiredRule message="Lütfen otomat modeli adı giriniz"/>
                </SimpleItem>
                <Item dataField="type" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: VendingTypeStore,
                          displayExpr: 'title',
                          valueExpr: 'id'
                      }}>
                    <Label text="Otomat Cinsi"/>
                    <RequiredRule message="Lütfen otomat cinsi seçiniz"/>
                </Item>
                <GroupItem caption="Makine Sıra Numarası Eşleştirmesi">
                    <DataGrid
                        dataSource={record?.optionNumberMatches}
                        showBorders={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        height={500}
                        ref={optionNumberMatchesGridRef}
                        paging={{enabled: false}}
                        scrolling={{useNative: true}}
                        onRowDblClick={(e) => {
                            optionNumberMatchesGridRef?.current?.instance.editRow(e.rowIndex);
                        }}
                    >
                        <GridSelection mode="single"/>
                        <GridToolbar>
                            <GridItem name="addRowButton" location="before" showText={"always"}/>
                        </GridToolbar>

                        <GridEditing
                            mode="row"
                            allowAdding={true}
                            allowUpdating={true}
                            allowDeleting={true}/>

                        <GridColumn dataField="optionNumber" caption="Sıra"
                                    dataType="number"
                                    sortIndex={1}
                                    sortOrder="asc"
                                    editorOptions={{format: "#", min: 1, max: 99}}/>

                        <GridColumn dataField="providerOptionNumber" caption="DMS Sıra"
                                    dataType="number"
                                    editorOptions={{format: "#", min: 1, max: 99}}/>
                    </DataGrid>
                </GroupItem>
                {hotDrinksVisible &&
                    <GroupItem caption="Sıcak Menüsü">
                        <DataGrid
                            dataSource={record?.hotDrinks}
                            showBorders={true}
                            hoverStateEnabled={true}
                            rowAlternationEnabled={true}
                            height={500}
                            ref={hotDrinksGridRef}
                            paging={{enabled: false}}
                            scrolling={{useNative: true}}
                            onRowDblClick={(e) => {
                                hotDrinksGridRef?.current?.instance.editRow(e.rowIndex);
                            }}
                        >
                            <GridSelection mode="single"/>
                            <GridToolbar>
                                <GridItem name="addRowButton" location="before" showText={"always"}/>
                            </GridToolbar>

                            <GridEditing
                                mode="row"
                                allowAdding={true}
                                allowUpdating={true}
                                allowDeleting={true}/>

                            <GridColumn dataField="optionNumber" caption="Sıra"
                                        width={60}
                                        dataType="number"
                                        sortIndex={1}
                                        sortOrder="asc"
                                        editorOptions={{format: "#", min: 1, max: 99}}/>

                            <GridColumn dataField="hotDrink" caption="Sıcak Adı"
                                        calculateDisplayValue={(e: VendingModelHotDrink) =>
                                            e.hotDrink ? e.hotDrink.title : ""
                                        }
                                        editCellRender={(cell) =>
                                            <SelectBox
                                                dataSource={HotDrinkStore}
                                                displayExpr={(p: HotDrink) => p?.title || ""}
                                                searchEnabled={true}
                                                minSearchLength={1}
                                                searchExpr="title"
                                                defaultValue={cell.value}
                                                onValueChanged={({value}) => {
                                                    cell.setValue(value);
                                                }}
                                            />
                                        }
                            >
                            </GridColumn>

                        </DataGrid>
                    </GroupItem>
                }
            </Form>
            <Toolbar>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>
        </>
    )
}