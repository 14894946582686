import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {Button, Toolbar} from "devextreme-react";
import React, {useEffect, useState} from "react";
import {Item as ToolbarItem} from "devextreme-react/toolbar";
import Form, {GroupItem, SimpleItem} from "devextreme-react/form";
import {Column as GridColumn, DataGrid} from "devextreme-react/data-grid";
import {CustomerVendingUnload} from "../../../domain/CustomerVendingUnload";
import {CustomerVendingUnloadStore} from "../../../datastore/CustomerVendingUnloadStore";

export function CustomerVendingUnloadsView() {
    const navigate = useNavigate();
    const [record, setRecord] = useState<CustomerVendingUnload>();
    const {id} = useParams();

    useEffect(() => {
        CustomerVendingUnloadStore.byKey(Number(id)).then((data) => {
            setRecord(data);
        });
    }, [id]);

    const goBack = () => {
        navigate(`/customer-vending-unloads`);
    }

    return (
        <>
            <Toolbar>
                <ToolbarItem location="before">
                    <Button text="OTOMAT İADELERİ" onClick={goBack} icon="undo"/>
                </ToolbarItem>
            </Toolbar>


            <Form formData={record} readOnly={true}
            >
                <GroupItem colCount={3}>
                    <SimpleItem dataField="technician.name" editorType="dxTextBox" label={{text: "Teknisyen"}}
                                editorOptions={{readOnly: true}}/>

                    <SimpleItem dataField="startedAt" editorType="dxDateBox"
                                label={{text: "Başlangıç Tarihi & Saati"}}
                                editorOptions={
                                    {
                                        dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
                                        type: 'datetime',
                                        readOnly: true
                                    }
                                }/>

                    <SimpleItem dataField="completedAt" editorType="dxDateBox"
                                label={{text: "Bitiş Tarihi & Saati"}}
                                editorOptions={
                                    {
                                        dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
                                        type: 'datetime',
                                        readOnly: true
                                    }
                                }/>
                </GroupItem>

                <GroupItem caption="Ürünler">
                    <DataGrid
                        dataSource={record?.products}
                        paging={{enabled: false}}
                        showBorders={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                    >
                        <GridColumn dataField="customerVendingProduct.optionNumber" caption="Sıra" width="80"/>
                        <GridColumn dataField="customerVendingProduct.product.brand.title" caption="Marka Adı"/>
                        <GridColumn dataField="customerVendingProduct.product.title" caption="Ürün Adı"/>
                        <GridColumn dataField="beforeCurrent" caption="Başlangıç" format="#0.##" width="100"/>
                        <GridColumn dataField="unloaded" caption="İade" format="#0.##" width="100"/>
                    </DataGrid>
                </GroupItem>
            </Form>
        </>
    )
}