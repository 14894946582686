import Form, {Item, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {isFormValid} from "../../../utils/utils";
import {confirm} from 'devextreme/ui/dialog';
import {TaskDefinitionStore} from "../../../datastore/TaskDefinitionStore";
import {TaskDefinition} from "../../../domain/TaskDefinition";
import {TechnicianTask} from "../../../domain/TechnicianTask";
import {TechnicianTaskStore} from "../../../datastore/TechnicianTaskStore";
import {TechnicianStore} from "../../../datastore/TechnicianStore";
import {TaskOptionalityStore} from "../../../datastore/TaskOptionalityStore";
import {CustomerStore} from "../../../datastore/CustomerStore";
import {VehicleStore} from "../../../datastore/VehicleStore";
import {WarehouseStore} from "../../../datastore/WarehouseStore";
import {Vending} from "../../../domain/Vending";
import {CustomerVendingStoreImpl} from "../../../datastore/CustomerVendingStore";
import {CustomerVending} from "../../../domain/CustomerVending";
import {BaseStore} from "../../../datastore/base/BaseStore";

export function TechnicianTasksEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const vendingSelectBoxRef = useRef<Item>(null);
    const [record, setRecord] = useState<TechnicianTask>();
    const {id} = useParams();
    const isNewRecord = !id;
    const [currentTask, setCurrentTask] = useState<TaskDefinition>();
    const [currentOptionality, setCurrentOptionality] = useState("");
    const customerScopes = new Set(["VENDING", "CUSTOMER"]);
    const [vendingSelectBoxDataSource, setVendingSelectBoxDataSource] = useState<BaseStore>();

    const updateVendingSelectBoxDataSource = (customerId: number | undefined = undefined) => {
        const dataSource = new CustomerVendingStoreImpl();
        dataSource.fetchDataMap = (data: CustomerVending) => {
            console.log(dataSource, data);
            return data.vending
        };
        if (customerId) dataSource.addStaticFilter("customerId", customerId);
        setVendingSelectBoxDataSource(dataSource);
    }

    useEffect(() => {
        if (!isNewRecord) {
            TechnicianTaskStore.byKey(Number(id)).then((data) => {
                setCurrentTask(data.task);
                setRecord(data);
                updateVendingSelectBoxDataSource(data.customer?.id);
            });
        } else {
            setRecord({});
            updateVendingSelectBoxDataSource();
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/technician-tasks")
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (isNewRecord) {
            TechnicianTaskStore.insert(record!).then(cancelClick);
        } else {
            TechnicianTaskStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`Bu teknisyen görevini silmek istiyor musunuz?`, "Teknisyen Görevi Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    TechnicianTaskStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni Teknisyen Görevi" : "Teknisyen Görevi Güncelle"}/>
            <Form ref={formRef}
                  formData={record}
                  onContentReady={(e) => {
                      e.component.getEditor("title")?.focus();
                  }}
            >
                <Item dataField="technician" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: TechnicianStore,
                          searchEnabled: true,
                          displayExpr: 'name'
                      }}>
                    <Label text="Teknisyen"/>
                    <RequiredRule message="Teknisyen seçiniz"/>
                </Item>

                <Item dataField="task" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: TaskDefinitionStore,
                          displayExpr: 'title',
                          searchEnabled: true,
                          onValueChanged: (e: any) => {
                              setCurrentTask(e.value);
                          }
                      }}>
                    <Label text="Görev"/>
                    <RequiredRule message="Görev seçiniz"/>
                </Item>

                <SimpleItem dataField="targetStartOn" editorType="dxDateBox" label={{text: "Hedef Tarih"}}
                            editorOptions={{openOnFieldClick: true}}/>

                <Item dataField="optionality" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: TaskOptionalityStore,
                          displayExpr: 'title',
                          valueExpr: 'id',
                          onValueChanged: (e: any) => {
                              setCurrentOptionality(e.value || "");
                          }
                      }}>
                    <Label text="Opsiyonellik"/>
                    <RequiredRule message="Opsiyonellik durumu seçiniz"/>
                </Item>

                {currentOptionality === 'OPTIONAL' &&
                    <SimpleItem dataField="optionalDays" editorType="dxNumberBox"
                                editorOptions={
                                    {
                                        format: "#0 gün",
                                        min: 1
                                    }
                                }>
                        <Label text="Opsiyonel Gün Sayısı"/>
                    </SimpleItem>
                }

                {customerScopes.has(currentTask?.scope || "") &&
                    <Item dataField="customer" editorType="dxSelectBox"
                          editorOptions={{
                              dataSource: CustomerStore,
                              searchEnabled: true,
                              displayExpr: 'title',
                              onValueChanged: async (e: any) => {
                                  updateVendingSelectBoxDataSource(e.value?.id);
                                  if (!isNewRecord) {
                                      if (record) {
                                          record.vending = undefined;
                                          setRecord(record);
                                      }
                                  }
                              }
                          }}>
                        <Label text="Müşteri"/>
                    </Item>
                }

                {currentTask?.scope === "VENDING" &&
                    <Item dataField="vending" editorType="dxSelectBox" ref={vendingSelectBoxRef}
                          editorOptions={{
                              dataSource: vendingSelectBoxDataSource,
                              searchEnabled: false,
                              displayExpr: (data: Vending) => {
                                  return data ? `${data.stockCode} - ${data.chassisCode} - ${data.model?.title}` : ""
                              }
                          }}>
                        <Label text="Otomat"/>
                    </Item>
                }

                {currentTask?.scope === "VEHICLE" &&
                    <Item dataField="vehicle" editorType="dxSelectBox"
                          editorOptions={{
                              dataSource: VehicleStore,
                              searchEnabled: true,
                              displayExpr: 'title'
                          }}>
                        <Label text="Araç"/>
                    </Item>
                }

                {currentTask?.scope === "WAREHOUSE" &&
                    <Item dataField="warehouse" editorType="dxSelectBox"
                          editorOptions={{
                              dataSource: WarehouseStore,
                              searchEnabled: true,
                              displayExpr: 'title'
                          }}>
                        <Label text="Depo"/>
                    </Item>
                }

                <SimpleItem dataField="description" editorType="dxTextArea"
                            editorOptions={{
                                maxLength: 1024,
                                height: 250
                            }
                            }
                            label={{text: "Ek açıklama"}}/>
            </Form>
            <Toolbar>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>
        </>
    )
}