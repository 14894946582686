import {EnumStore} from "./base/EnumStore";

export const InventoryTransactionTypeStore = new EnumStore({
    key: "id",
    data: [
        {"id": "ORDER_PRODUCT", "title": "Ürün Siparişi"},
        {"id": "PRODUCT_RETURN", "title": "Ürün İade"},
        {"id": "LOADING_VENDING", "title": "Otomat Yükleme"},
        {"id": "UNLOADING_VENDING", "title": "Otomat Boşaltma"},
        {"id": "LOADING_VEHICLE", "title": "Araç Yükleme"},
        {"id": "UNLOADING_VEHICLE", "title": "Araç Boşaltma"},
        {"id": "PRODUCT_SELL", "title": "Ürün Satışı"},
        {"id": "CHECK", "title": "Kontrol"},
    ]
})