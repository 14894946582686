import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {signIn as sendSignInRequest} from '../api/auth';
import {useInterval} from "usehooks-ts";
import {axiosTokenConfig} from "./axios";

const LOCAL_STORAGE_TOKEN = "auth_token";
const LOCAL_STORAGE_EXPIRATION = "auth_expiration";
const LOCAL_STORAGE_USER = "user";

function AuthProvider(props) {
    const initialToken = localStorage.getItem(LOCAL_STORAGE_TOKEN);
    const initialExpiration = localStorage.getItem(LOCAL_STORAGE_EXPIRATION);
    const initialUser = localStorage.getItem(LOCAL_STORAGE_USER);

    const [authToken, setAuthToken] = useState(initialToken);
    const [authExpiration, setAuthExpiration] = useState(initialExpiration ? new Date(Date.parse(initialExpiration)) : null);

    const [user, setUser] = useState(initialUser ? JSON.parse(initialUser) : null);
    const [loading, setLoading] = useState(true);

    const clearToken = () => {
        console.log("Token is clearing");
        setAuthToken(null);
        setAuthExpiration(null);
        localStorage.removeItem(LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_EXPIRATION);
        localStorage.removeItem(LOCAL_STORAGE_USER);
    };

    const tokenUpdate = function (token, expiration, user) {
        console.log("New token is received " + token);
        // store into the local storage
        localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
        localStorage.setItem(LOCAL_STORAGE_EXPIRATION, expiration.toISOString());
        localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));

        setAuthToken(token);
        setAuthExpiration(expiration);
        setUser(user);
        axiosTokenConfig(token);
    }

    const signIn = useCallback(async (username, password) => {
        const result = await sendSignInRequest(username, password);
        if (result.isOk) {
            tokenUpdate(result.data.token, result.data.expiration, result.data.user);
        }
        return result;
    }, []);

    const signOut = useCallback(() => {
        localStorage.clear();
        setUser(undefined);
        clearToken();
    }, []);

    useEffect(() => {
        // check if the token is expired
        if (authExpiration && authExpiration < new Date()) {
            console.log(`Token is expired. Expiry date is ${authExpiration}`);
            clearToken();
        }
        else if (!authExpiration || !authToken) {
            setUser(undefined);
            clearToken();
        } else {
            axiosTokenConfig(authToken);
        }
        setLoading(false);
    }, [authExpiration, authToken]);

    useInterval(() => {
        if (authExpiration && authExpiration < new Date()) {
            console.log(`Token is expired. Expiry date is ${authExpiration}`);
            setUser(undefined);
            clearToken();
        }
    }, authExpiration ? 1000 : null);

    return (
        <AuthContext.Provider value={{user, signIn, signOut, loading, authToken}} {...props} />
    );
}

const AuthContext = createContext({loading: true});
const useAuth = () => useContext(AuthContext);

export {AuthProvider, useAuth}
