import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {Button, Toolbar} from "devextreme-react";
import React, {useEffect, useState} from "react";
import {Item as ToolbarItem} from "devextreme-react/toolbar";
import Form, {GroupItem, Item, Label} from "devextreme-react/form";
import {
    Column,
    Column as GridColumn,
    DataGrid,
    Lookup,
    Selection
} from "devextreme-react/data-grid";
import {CustomerVendingStore} from "../../../datastore/CustomerVendingStore";
import {CustomerStore} from "../../../datastore/CustomerStore";
import {VendingStore} from "../../../datastore/VendingStore";
import {Vending} from "../../../domain/Vending";
import {CustomerVendingVersionStore} from "../../../datastore/CustomerVendingVersionStore";
import {CustomerVendingVersionStateStore} from "../../../datastore/CustomerVendingVersionStateStore";
import {CustomerVending} from "../../../domain/CustomerVending";


export function CustomerVendingsState() {
    const navigate = useNavigate();
    const [record, setRecord] = useState<CustomerVending>();
    const {id} = useParams();

    useEffect(() => {
        CustomerVendingStore.byKey(Number(id)).then((data) => {
            setRecord(data);
        });
    }, [id]);

    const goBack = () => {
        navigate(`/customer-vendings`);
    }

    const editClick = () => {
        navigate(`/customer-vendings/${id}`);
    }

    const newVersion = () => {
        navigate(`/customer-vendings/${id}/versions/new`);
    }

    const goVersion = (versionId: any) => {
        navigate(`/customer-vendings/${id}/versions/${versionId}`)
    }

    return (
        <>
            <Toolbar>
                <ToolbarItem location="before">
                    <Button text="MÜŞTERİ OTOMAT LİSTESİ" onClick={goBack} icon="undo"/>
                </ToolbarItem>
                <ToolbarItem location="before">
                    <Button text="MÜŞTERİ OTOMATI GÜNCELLE" onClick={editClick} icon="edit"/>
                </ToolbarItem>
                <ToolbarItem location="before">
                    <Button text="YENİ VERSİYON" onClick={newVersion} icon="fas fa-code-commit"/>
                </ToolbarItem>
            </Toolbar>


            <Form formData={record} readOnly={true}>
                <GroupItem colCount={4}>
                    <Item dataField="customer" editorType="dxSelectBox"
                          editorOptions={{
                              dataSource: CustomerStore,
                              displayExpr: 'title',
                              searchEnabled: true
                          }}>
                        <Label text="Müşteri"/>
                    </Item>
                    <Item dataField="vending" editorType="dxSelectBox"
                          editorOptions={{
                              dataSource: VendingStore,
                              displayExpr: (data: Vending) => {
                                  return data ? data.stockCode + " - " + data.chassisCode : ""
                              },
                              searchExpr: 'search',
                              searchEnabled: true
                          }}>
                        <Label text="Otomat"/>
                    </Item>
                    <Item dataField="activeVersion" editorType="dxTextBox"
                          editorOptions={{
                              value: record?.activeVersion?.toString() || "",
                              readOnly: true
                          }}>
                        <Label text="Aktif Versiyon"/>
                    </Item>
                    <Item dataField="technicianVersion" editorType="dxTextBox"
                          editorOptions={{
                              value: record?.technicianVersion?.toString() || "",
                              readOnly: true
                          }}>
                        <Label text="Teknisyen Versiyonu"/>
                    </Item>
                </GroupItem>
            </Form>

            <DataGrid
                dataSource={CustomerVendingVersionStore(Number(id))}
                showBorders={true}
                hoverStateEnabled={true}
                rowAlternationEnabled={true}
                height={300}
                onSelectionChanged={({selectedRowsData}) => {
                    goVersion(selectedRowsData[0].version);
                }}
            >
                <Selection mode="single"/>
                <GridColumn dataField="version" caption="Versiyon" width="100"/>
                <GridColumn dataField="description" caption="Açıklama"/>
                <GridColumn dataField="createdAt" caption="Oluşturulma" dataType="datetime" width="150"/>
                <GridColumn dataField="lastModifiedAt" caption="Son Güncellenme" dataType="datetime" width="150"/>
                <GridColumn dataField="targetActiveStateOn" caption="Hedef Tarih" dataType="date" width="120"/>
                <GridColumn dataField="technicianVersionAt" caption="Teknisyen İşlem" dataType="datetime" width="150"/>
                <Column dataField="state" caption="Durum" width="120">
                    <Lookup dataSource={CustomerVendingVersionStateStore} valueExpr="id" displayExpr="title"/>
                </Column>
            </DataGrid>
        </>
    )
}