import {Column} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import React from "react";
import {CustomerVendingVisitStore} from "../../../datastore/CustomerVendingVisitStore";

export function CustomerVendingVisitsList() {
    return (
        <>
            <BaseDataGridList
                dataSource={CustomerVendingVisitStore}
                header="Otomat Ziyaretleri"
            >
                <Column dataField="customerVending.customer.title" caption="Müşteri" allowFiltering={false}/>
                <Column dataField="customerVending.vending.stockCode" caption="Otomat" allowFiltering={false}/>
                <Column dataField="technician.name" caption="Teknisyen" allowFiltering={false}/>
                <Column dataField="startedAt" caption="Başlangıç" dataType="datetime" allowFiltering={false}/>
                <Column dataField="completedAt" caption="Bitiş" dataType="datetime" allowFiltering={false}/>
            </BaseDataGridList>
        </>
    )
}
