import {EnumStore} from "./base/EnumStore";

export const InventoryTypeStore = new EnumStore({
    key: "id",
    data: [
        {"id": "CUSTOMER_VENDING", "title": "Müşteri Otomatı"},
        {"id": "VEHICLE", "title": "Araç"},
        {"id": "WAREHOUSE", "title": "Depo"},
        {"id": "SUPPLIER", "title": "Tedarikçi"},
        {"id": "UNKNOWN", "title": "Bilinmiyor"},
    ]
})