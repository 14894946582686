import {EnumStore} from "./base/EnumStore";

export const TechnicianHandheldTerminalStateStore = new EnumStore({
        key: "id",
        data: [
            {"id": "GIVEN", "title": "Teknisyende"},
            {"id": "BROKEN", "title": "Bozuk"},
            {"id": "RETURNED", "title": "İade Edildi"},
        ]
    }
);