import Form, {GroupItem, Item, Label, RangeRule, RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import React, {useEffect, useRef, useState} from "react";
import {InventoryTransaction} from "../../../domain/InventoryTransaction";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {InventoryTransactionStore} from "../../../datastore/InventoryTransactionStore";
import {
    Column as GridColumn,
    DataGrid,
    Editing as GridEditing,
    Item as GridItem,
    Selection as GridSelection,
    Toolbar as GridToolbar,
} from "devextreme-react/data-grid";
import {Button, Popup} from "devextreme-react";
import {Product} from "../../../domain/Product";
import {ProductStore} from "../../../datastore/ProductStore";
import Guid from "devextreme/core/guid";
import {isFormValid} from "../../../utils/utils";
import {confirm} from "devextreme/ui/dialog";
import {InventoryTypeStore} from "../../../datastore/InventoryTypeStore";
import {InventoryTransactionTypeStore} from "../../../datastore/InventoryTransactionTypeStore";
import {InventoryStore} from "../../../datastore/InventoryStore";
import {Inventory} from "../../../domain/Inventory";
import {InventoryTransactionProduct} from "../../../domain/InventoryTransactionProduct";

export function InventoryTransactionEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [selectedInventoryTransactionProduct, setSelectedInventoryTransactionProduct] = useState<InventoryTransactionProduct>();
    const [editFormTitle, setEditFormTitle] = useState<string>();
    const [editFormSaveTitle, setEditFormSaveTitle] = useState<string>();
    const [record, setRecord] = useState<InventoryTransaction>();
    const [addNewProduct, setAddNewProduct] = useState(false);
    const inventoryTransactionProductEditForm = useRef<Form>(null);

    const {id} = useParams();
    const isNewRecord = !id;

    useEffect(() => {
        if (!isNewRecord) {
            InventoryTransactionStore.byKey(Number(id)).then((data) => {
                if (!data.products) data.products = [];
                data.products.map((p) => p.id = new Guid().toString());
                setRecord(data);
            });
        } else {
            setRecord({
                products: []
            });
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/inventory-transactions")
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (isNewRecord) {
            InventoryTransactionStore.insert(record!).then(cancelClick);
        } else {
            InventoryTransactionStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`<b>${record?.id}</b> nolu ürün hareketini silmek istiyor musunuz?`, "Ürün Hareketi Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    InventoryTransactionStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    const addProductClick = () => {
        setAddNewProduct(true);
        setEditFormTitle("Ürün Ekle");
        setEditFormSaveTitle("Ürün Ekle");
        setSelectedInventoryTransactionProduct({
            id: new Guid().toString()
        });
        setEditFormVisible(true);
    }

    const selectProduct = (e: InventoryTransactionProduct) => {
        setAddNewProduct(false);
        setEditFormTitle("Ürün Güncelle");
        setEditFormSaveTitle("Ürün Güncelle");
        setSelectedInventoryTransactionProduct(Object.assign({}, e));
        setEditFormVisible(true);
    }

    const cancelProductClick = () => {
        setEditFormVisible(false);
    }

    const saveProductClick = () => {
        if (!isFormValid(inventoryTransactionProductEditForm)) return;
        if (addNewProduct) {
            record!.products = (record?.products || []).concat(selectedInventoryTransactionProduct!);
        } else {
            const index = record!.products?.findIndex((p) => p.id === selectedInventoryTransactionProduct?.id);
            if (index !== undefined && index > -1) {
                record!.products?.splice(index, 1, selectedInventoryTransactionProduct!);
                // refresh
                record!.products = (record?.products || []).concat([]);
            }
        }
        setEditFormVisible(false);
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni Ürün Hareketi" : "Ürün Hareketi Güncelle"}/>
            <Form ref={formRef}
                  formData={record}
                  onContentReady={(e) => {
                      e.component.getEditor("type")?.focus();
                  }}
            >
                <GroupItem colCount={3}>
                    <Item dataField="type" editorType="dxSelectBox"
                          editorOptions={{
                              dataSource: InventoryTransactionTypeStore,
                              displayExpr: 'title',
                              valueExpr: 'id'
                          }}>
                        <Label text="Tip"/>
                        <RequiredRule message="Ürün hareketi tipi seçiniz"/>
                    </Item>
                    <Item dataField="inverseId" editorType="dxNumberBox">
                        <Label text="Ref Id"/>
                    </Item>
                </GroupItem>

                <Item dataField="fromInventory" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: InventoryStore,
                          displayExpr: (data: Inventory) => {
                              return data ? `${InventoryTypeStore.findByKey(data.type)?.title} - ${data.reverseId} - ${data.title}` : ""
                          },
                          searchExpr: 'title',
                          searchEnabled: true
                      }}>
                    <Label text="Kaynak Depo"/>
                    <RequiredRule message="Kaynak depo seçiniz"/>
                </Item>

                <Item dataField="toInventory" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: InventoryStore,
                          displayExpr: (data: Inventory) => {
                              return data ? `${InventoryTypeStore.findByKey(data.type)?.title} - ${data.reverseId} - ${data.title}` : ""
                          },
                          searchExpr: 'title',
                          searchEnabled: true
                      }}>
                    <Label text="Hedef Depo"/>
                    <RequiredRule message="Hedef depo seçiniz"/>
                </Item>


                <GroupItem caption="Ürünler">
                    <DataGrid
                        dataSource={record?.products}
                        showBorders={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        height={300}
                        onRowClick={(e) => {
                            selectProduct(e.data);
                        }}
                    >
                        <GridSelection mode="single"/>
                        <GridEditing
                            mode="row"
                            allowDeleting={true}/>
                        <GridToolbar>
                            <GridItem location="before">
                                <Button
                                    icon='plus'
                                    text="Ürün Ekle"
                                    onClick={addProductClick}
                                />
                            </GridItem>
                        </GridToolbar>
                        <GridColumn dataField="product.brand.title" caption="Marka Adı"/>
                        <GridColumn dataField="product.title" caption="Ürün Adı"/>
                        <GridColumn dataField="quantity" caption="Miktar" format="#0.##" width="80"/>
                    </DataGrid>
                </GroupItem>
            </Form>
            <Toolbar>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>

            <Popup
                visible={editFormVisible}
                showCloseButton={true}
                onHiding={cancelProductClick}
                title={editFormTitle}
                width={800}
                height={470}
            >
                <Form formData={selectedInventoryTransactionProduct} ref={inventoryTransactionProductEditForm}>
                    <SimpleItem dataField="product"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    dataSource: ProductStore,
                                    displayExpr: (data: Product) => {
                                        return data ? data.brand?.title + " - " + data.title : ""
                                    },
                                    searchExpr: "title",
                                    searchEnabled: true,
                                    minSearchLength: 1
                                }}
                    >
                        <RequiredRule message="Lütfen ürün seçiniz"/>
                        <Label text="Ürün"/>
                    </SimpleItem>
                    <SimpleItem dataField="quantity" editorType="dxNumberBox"
                                editorOptions={
                                    {
                                        format: "#0.##",
                                        min: 0
                                    }
                                }>
                        <RequiredRule message="Lütfen miktar giriniz"/>
                        <RangeRule min={1} message="Lütfen miktar giriniz"/>
                        <Label text="Miktar"/>
                    </SimpleItem>
                </Form>
                <Toolbar>
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "İptal", icon: "back", onClick: cancelProductClick}}/>
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: editFormSaveTitle, icon: "save", onClick: saveProductClick}}/>
                </Toolbar>
            </Popup>
        </>
    )
}