import {BaseStore, ListResponse} from "./base/BaseStore";
import {CustomerVending} from "../domain/CustomerVending";
import axios, {AxiosResponse} from "axios";

export class CustomerVendingStoreImpl extends BaseStore<CustomerVending, number> {
    constructor() {
        super("/api/v1/customer-vendings");
    }

    getVendingModelWithModel(id: number): Promise<AxiosResponse<CustomerVending>> {
        return axios.get(`${this.url}/${id}?model=true`);
    }

    listByCustomers(customerId: number, size: number): Promise<AxiosResponse<ListResponse<CustomerVending>>> {
        return axios.get(`${this.url}?customerId=${customerId}&size=${size}`);
    }
}

export const CustomerVendingStore = new CustomerVendingStoreImpl();