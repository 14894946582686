import Box, {Item} from "devextreme-react/box";
import {DashboardCard} from "../../../layouts/dashboard-card/DashboardCard";

export function SomeCountsIndicator() {
    return (
        <>
            <Box direction="row" width="100%">
                <Item ratio={1}>
                    <DashboardCard title="Müşteri Sayısı" titleStyle={{align: 'center'}}>
                        <div className="visited-indicator">
                            <div className="text">185</div>
                        </div>
                    </DashboardCard>
                </Item>
                <Item ratio={1}>
                    <DashboardCard title="Sahadaki Otomatlar" titleStyle={{align: 'center'}}>
                        <div className="visited-indicator">
                            <div className="text">514</div>
                        </div>
                    </DashboardCard>
                </Item>
                <Item ratio={1}>
                    <DashboardCard title="Depodaki Otomatlar" titleStyle={{align: 'center'}}>
                        <div className="visited-indicator">
                            <div className="text">16</div>
                        </div>
                    </DashboardCard>
                </Item>
                <Item ratio={1}>
                    <DashboardCard title="Kurulacak Otomatlar" titleStyle={{align: 'center'}}>
                        <div className="visited-indicator">
                            <div className="text">5</div>
                        </div>
                    </DashboardCard>
                </Item>
            </Box>
        </>
    )
}