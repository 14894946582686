import React, {PropsWithChildren} from "react";
import './dashboard-card.scss';

interface Props extends PropsWithChildren {
    title: string,
    titleStyle?: {
        align?: 'center' | 'left'
    },
    cardStyle?: {
        backgroundColor?: string
    },
    description?: string
}


export class DashboardCard extends React.Component<Props> {
    render() {
        const cardStyle = {
            backgroundColor: this.props.cardStyle?.backgroundColor || 'white'
        }
        const titleStyle = {
            textAlign: this.props.titleStyle?.align || 'left'
        }
        const hasHeader = !!this.props.title || !!this.props.description
        return <>
            <div className={'dashboard-card'}>
                <div className={'dx-card responsive-paddings'} style={cardStyle}>
                    {hasHeader &&
                        <div className={'header'}>
                            {this.props.title &&
                                <div className={'title'} style={titleStyle}>{this.props.title}</div>
                            }
                            {this.props.description &&
                                <div className={'description'}>{this.props.description}</div>
                            }
                        </div>
                    }
                    {this.props.children}
                </div>
            </div>
        </>
    }
}