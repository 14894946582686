import {Button} from "devextreme-react";
import {dxButtonOptions} from "devextreme/ui/button";
import {useNavigate} from "react-router";

interface Props extends dxButtonOptions{
    href: string
}
export function NavigationButton(props: Props) {
    const navigate = useNavigate();
    const buttonClick = () => navigate(props.href);

    return (
        <Button {...props}
            onClick={buttonClick}
        />

    )
}