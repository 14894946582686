import Form from "devextreme-react/form";
import {alert} from "devextreme/ui/dialog";
import {trigger} from "./events";

export function isFormValid(formRef: React.RefObject<Form>): boolean {
    const validationResult = formRef?.current?.instance.validate();
    console.log(validationResult);
    if (validationResult) {
        if (validationResult.brokenRules?.length) {
            const brokenRule: any = validationResult.brokenRules[0];
            alert(brokenRule.message || "Lütfen ekrandaki gerekli alanları doldurunuz", "Hata")
                .then(() => {
                });
        }
        return validationResult?.isValid || false;
    }
    return true;
}

export const LOAD_PANEL_SHOW = "load-panel:show";
export const LOAD_PANEL_HIDE = "load-panel:hide";
export function showLoadPanel() {
    trigger(LOAD_PANEL_SHOW);
}

export function hideLoadPanel() {
    trigger(LOAD_PANEL_HIDE);
}