import {
    Column,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {TransportTypeStore} from "../../../datastore/TransportTypeStore";

export function TransportTypesList() {
    return (
        <BaseDataGridList
            dataSource={TransportTypeStore}
            header="Nakliye Şekilleri"
            editHref="/transport-types"
        >
            <Column dataField="title" caption="Nakliye Şekli Adı"/>
        </BaseDataGridList>
    )
}
