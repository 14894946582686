import {BaseStore} from "./base/BaseStore";
import axios, {AxiosResponse} from "axios";
import {TechnicianTask} from "../domain/TechnicianTask";

class TechnicianTaskImpl extends BaseStore<TechnicianTask, number> {
    constructor() {
        super("/api/v1/technician-tasks");
    }

    cancelByOfficer(id: number): Promise<AxiosResponse<void>> {
        return axios.post(`${this.url}/${id}/cancel-by-officer`);
    }
}

export const TechnicianTaskStore = new TechnicianTaskImpl();