import {Column, Lookup,} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {VendingTypeStore} from "../../../datastore/VendingTypeStore";
import {VendingStateStore} from "../../../datastore/VendingStateStore";
import {Vending} from "../../../domain/Vending";
import {VendingStore} from "../../../datastore/VendingStore";

export function VendingsList() {
    return (
        <BaseDataGridList
            dataSource={VendingStore}
            header="Otomatlar"
            editHref="/vendings"
        >
            <Column dataField="stockCode" caption="Barkod"/>
            <Column dataField="chassisCode" caption="Şase Kodu"/>
            <Column dataField="model.title" caption="Otomat Modeli"/>
            <Column dataField="model.type" caption="Otomat Cinsi"
                    calculateDisplayValue={(data: Vending) => VendingTypeStore.findByKey(data?.model?.type)?.title}
                    calculateCellValue={(data: Vending) => VendingTypeStore.findByKey(data?.model?.type)?.title}
                    lookup={
                        {
                            dataSource: VendingTypeStore,
                            valueExpr: "id",
                            displayExpr: "title",
                            allowClearing: true
                        }
                    }
            >
            </Column>
            <Column dataField="state" caption="Durum"
                    calculateDisplayValue={(data: Vending) => VendingStateStore.findByKey(data.state)?.title}
                    calculateCellValue={(data: Vending) => VendingStateStore.findByKey(data.state)?.title}
                >
                <Lookup dataSource={VendingStateStore} valueExpr="id" displayExpr="title"/>
            </Column>
        </BaseDataGridList>
    )
}
