import Form, {RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import {useEffect, useState} from "react";
import {Technician} from "../../../domain/Technician";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {TechnicianStore} from "../../../datastore/TechnicianStore";
import {confirm} from "devextreme/ui/dialog";

export function TechniciansEdit() {
    const navigate = useNavigate();
    const [record, setRecord] = useState<Technician>();
    const {id} = useParams();
    const isNewRecord = !id;

    useEffect(() => {
        if (!isNewRecord) {
            TechnicianStore.byKey(Number(id)).then((data) => {
                setRecord(data);
            });
        } else {
            setRecord({});
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/technicians")
    }

    const saveClick = () => {
        if (isNewRecord) {
            TechnicianStore.insert(record!).then(cancelClick);
        } else {
            TechnicianStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`<b>${record?.name}</b> adlı teknisyeni silmek istiyor musunuz?`, "Teknisyen Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    TechnicianStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni Teknisyen" : "Teknisyen Güncelle"}/>
            <Form formData={record}
                  onContentReady={(e) => {
                      e.component.getEditor("name")?.focus();
                  }}
            >
                <SimpleItem dataField="name" editorType="dxTextBox" label={{text: "Teknisyen Adı"}}>
                    <RequiredRule message="Lütfen teknisyen adı giriniz"/>
                </SimpleItem>
                <SimpleItem dataField="personalCode" editorType="dxTextBox" label={{text: "Personel Kodu"}}>
                    <RequiredRule message="Lütfen personel kodu giriniz"/>
                </SimpleItem>

            </Form>
            <Toolbar>
                <ToolbarItem widget="dxButton" location="before" options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <ToolbarItem widget="dxButton" location="before" options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>
        </>
    )
}