import {
    Column,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {PaymentDeviceStore} from "../../../datastore/PaymentDeviceStore";

export function PaymentDevicesList() {
    return (
        <BaseDataGridList
            dataSource={PaymentDeviceStore}
            header="Ödeme Cihazları"
            editHref="/payment-devices"
        >
            <Column dataField="paymentSystem.title" caption="Ödeme Cihazı"/>
            <Column dataField="chassisCode" caption="Şase Kodu"/>
            <Column dataField="mobile" caption="Hat No"/>
        </BaseDataGridList>
    )
}
