import {
    Column,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {TechnicianVehicleStore} from "../../../datastore/TechnicianVehicleStore";
import {TechnicianVehicle} from "../../../domain/TechnicianVehicle";
import {TechnicianVehicleStateStore} from "../../../datastore/TechnicianVehicleStateStore";

export function TechnicianVehiclesList() {
    return (
        <BaseDataGridList
            dataSource={TechnicianVehicleStore}
            header="Teknisyen Araç Atamaları"
            editHref="/technician-vehicles"
        >
            <Column dataField="technician.personalCode" caption="Personel Kodu"/>
            <Column dataField="technician.name" caption="Teknisyen Adı"/>
            <Column dataField="vehicle.licensePlate" caption="Araç Plakası"/>
            <Column dataField="state" caption="Durum"
                    calculateDisplayValue={(data: TechnicianVehicle) => TechnicianVehicleStateStore.findByKey(data?.state)?.title}
                    calculateCellValue={(data: TechnicianVehicle) => TechnicianVehicleStateStore.findByKey(data?.state)?.title}
                    lookup={
                        {
                            dataSource: TechnicianVehicleStateStore,
                            valueExpr: "id",
                            displayExpr: "title",
                            allowClearing: true
                        }
                    }
            />
        </BaseDataGridList>
    )
}
