import Box, {Item} from 'devextreme-react/box';
import {DashboardCard} from "../../../layouts/dashboard-card/DashboardCard";
import './visited-indicator.scss';

export function VisitedIndicator() {
    //  margin={{top: 5, bottom: 0, left: 0, right: 0}}
    return (
        <>
            <Box direction="row" width="100%">
                <Item ratio={1}>
                    <DashboardCard title="Günlük" titleStyle={{align: 'center'}}>
                        <div className="visited-indicator">
                            <div className="text">8 / 10</div>
                        </div>
                    </DashboardCard>
                </Item>
                <Item ratio={1}>
                    <DashboardCard title="Haftalık" titleStyle={{align: 'center'}}>
                        <div className="visited-indicator">
                            <div className="text">20 / 102</div>
                        </div>
                    </DashboardCard>
                </Item>
                <Item ratio={1}>
                    <DashboardCard title="Aylık" titleStyle={{align: 'center'}}>
                        <div className="visited-indicator">
                            <div className="text">56 / 678</div>
                        </div>
                    </DashboardCard>
                </Item>
            </Box>
        </>
    )
}