import {Column, Lookup,} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import React, {useRef, useState} from "react";
import {TechnicianOrderStore} from "../../../datastore/TechnicianOrderStore";
import {TechnicianOrderStateStore} from "../../../datastore/TechnicianOrderStateStore";
import Form, {Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {Popup} from "devextreme-react";
import {TechnicianStore} from "../../../datastore/TechnicianStore";
import {Technician} from "../../../domain/Technician";
import {hideLoadPanel, isFormValid, showLoadPanel} from "../../../utils/utils";
import {alert, confirm} from "devextreme/ui/dialog";
import {trigger} from "../../../utils/events";
import {formatDate} from "devextreme/localization";

interface TechnicianOrderCreateDate {
    technician?: Technician,
    visitDate?: Date
}

export function TechnicianOrdersList() {
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [selectedTechnicianOrder, setSelectedTechnicianOrder] = useState<TechnicianOrderCreateDate>();
    const technicianOrderEditForm = useRef<Form>(null);

    const showEditForm = () => {
        setSelectedTechnicianOrder({});
        setEditFormVisible(true);
    }

    const editFormSaveClick = () => {
        if (!isFormValid(technicianOrderEditForm)) return;
        const technician = selectedTechnicianOrder!!.technician!!;
        const visitDate = selectedTechnicianOrder!!.visitDate!!;

        confirm(`${technician.name} adlı teknisyene ${formatDate(visitDate, "dd.MM.yyyy")} ` +
            "tarihli ziyaret için gerekli ürünlerin analizi yapılacaktır. " +
            "Teknisyen siparişini oluşturmak istiyor musunuz?", "Teknisyen Siparişi Oluştur")
            .then((dialogResult) => {
                if (dialogResult) {
                    showLoadPanel();
                    TechnicianOrderStore.createTechnicianVisitDate(technician.id!!, visitDate).then(() => {
                        alert("Teknisyen siparişi oluşturuldu.", "Teknisyen Siparişi Oluştur")
                            .then(() => {
                                trigger('technicianOrderList:refresh');
                                editFormCancelClick();
                            })
                    }).finally(() => {
                        hideLoadPanel();
                    });
                }
            });


    }

    const editFormCancelClick = () => {
        setEditFormVisible(false);
    }

    return (
        <>
            <BaseDataGridList
                dataSource={TechnicianOrderStore}
                header="Teknisyen Siparişleri"
                newButtonHandler={showEditForm}
                editHref="/technician-orders"
                eventContext="technicianOrderList"
            >
                <Column dataField="createdAt" caption="Tarih" dataType="datetime" allowFiltering={false} width="150"/>
                <Column dataField="technician.name" caption="Teknisyen"/>
                <Column dataField="completedAt" caption="Tamamlanma Tarihi" dataType="datetime" allowFiltering={false}
                        width="150"/>
                <Column dataField="state" caption="Durum" width="120">
                    <Lookup dataSource={TechnicianOrderStateStore} valueExpr="id" displayExpr="title"/>
                </Column>
            </BaseDataGridList>

            <Popup
                visible={editFormVisible}
                showCloseButton={true}
                onHiding={editFormCancelClick}
                title="Teknisyen Siparişi Oluştur"
                width={500}
                height={350}
            >
                <Form formData={selectedTechnicianOrder} ref={technicianOrderEditForm}>
                    <SimpleItem dataField="technician"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    dataSource: TechnicianStore,
                                    displayExpr: (data: Technician) => {
                                        return data ? `${data.personalCode} - ${data.name}` : ""
                                    },
                                    searchExpr: "name",
                                    searchEnabled: true,
                                    minSearchLength: 0
                                }}
                    >
                        <RequiredRule message="Lütfen teknisyen seçiniz"/>
                        <Label text="Teknisyen"/>
                    </SimpleItem>
                    <SimpleItem dataField="visitDate" editorType="dxDateBox" label={{text: "Ziyaret Tarihi"}}
                                editorOptions={
                                    {
                                        type: 'date',
                                        openOnFieldClick: true
                                    }
                                }>
                        <RequiredRule message="Lütfen ziyaret tarihi seçiniz"/>
                    </SimpleItem>
                </Form>
                <Toolbar>
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "İptal", icon: "back", onClick: editFormCancelClick}}/>
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Oluştur", icon: "save", onClick: editFormSaveClick}}/>
                </Toolbar>
            </Popup>
        </>
    )
}
