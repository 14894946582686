import Form, {Item, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {isFormValid} from "../../../utils/utils";
import {confirm} from 'devextreme/ui/dialog';
import {TaskDefinitionStore} from "../../../datastore/TaskDefinitionStore";
import {TaskDefinition} from "../../../domain/TaskDefinition";
import {TaskScopeStore} from "../../../datastore/TaskScopeStore";

export function TaskDefinitionsEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const [record, setRecord] = useState<TaskDefinition>();
    const {id} = useParams();
    const isNewRecord = !id;
    const takeVendingPictureScopes = new Set(["VENDING"]);
    const stockCountingScopes = new Set(["VENDING", "VEHICLE", "WAREHOUSE"]);
    const [currentScope, setCurrentScope] = useState("");

    useEffect(() => {
        if (!isNewRecord) {
            TaskDefinitionStore.byKey(Number(id)).then((data) => {
                setRecord(data);
            });
        } else {
            setRecord({});
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/task-definitions")
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (isNewRecord) {
            TaskDefinitionStore.insert(record!).then(cancelClick);
        } else {
            TaskDefinitionStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`<b>${record?.title}</b> görevini silmek istiyor musunuz?`, "Görev Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    TaskDefinitionStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni Görev" : "Görev Güncelle"}/>
            <Form ref={formRef}
                  formData={record}
                  onContentReady={(e) => {
                      e.component.getEditor("title")?.focus();
                  }}
            >
                <SimpleItem dataField="title" editorType="dxTextBox" label={{text: "Görev Adı", maxLength: 50}}>
                    <RequiredRule message="Lütfen görev adı giriniz"/>
                </SimpleItem>
                <Item dataField="scope" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: TaskScopeStore,
                          displayExpr: 'title',
                          valueExpr: 'id',
                          onValueChanged: (e: any) => {
                              setCurrentScope(e.value || "");
                          }
                      }}>
                    <Label text="Kapsam"/>
                    <RequiredRule message="Görev kapsamı seçiniz"/>
                </Item>
                {takeVendingPictureScopes.has(currentScope) &&
                    <SimpleItem dataField="takeVendingPicture" editorType="dxCheckBox"
                                label={{text: "Otomat Fotoğrafı"}}
                                editorOptions={{text: "Fotoğraf Çekilsin"}}
                    />
                }
                {stockCountingScopes.has(currentScope) &&
                    <SimpleItem dataField="stockCounting" editorType="dxCheckBox"
                                label={{text: "Ürün Sayımı"}}
                                editorOptions={{text: "Ürün Sayımı Yapılsın"}}
                    />
                }
                <SimpleItem dataField="description" editorType="dxTextArea"
                            editorOptions={{
                                maxLength: 1024,
                                height: 250
                            }
                            }
                            label={{text: "Açıklama"}}/>
            </Form>
            <Toolbar>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>
        </>
    )
}