import {
    Column,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {WarehouseStore} from "../../../datastore/WarehouseStore";

export function WarehousesList() {
    return (
        <BaseDataGridList
            dataSource={WarehouseStore}
            header="Depolar"
            editHref="/warehouses"
            selectedHrefSuffix="/state"
        >
            <Column dataField="title" caption="Depo Adı"/>
        </BaseDataGridList>
    )
}
