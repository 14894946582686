import {
    Column, Lookup,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {TaskDefinitionStore} from "../../../datastore/TaskDefinitionStore";
import React from "react";
import {TaskScopeStore} from "../../../datastore/TaskScopeStore";

export function TaskDefinitionsList() {
    return (
        <BaseDataGridList
            dataSource={TaskDefinitionStore}
            header="Görev Tanımları"
            editHref="/task-definitions"
        >
            <Column dataField="title" caption="Görev Adı"/>
            <Column dataField="scope" caption="Kapsam" width="120">
                <Lookup dataSource={TaskScopeStore} valueExpr="id" displayExpr="title"/>
            </Column>
        </BaseDataGridList>
    )
}
