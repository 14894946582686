import {
    Column,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {OperatingSystemStore} from "../../../datastore/OperatingSystemStore";

export function OperatingSystemsList() {
    return (
        <BaseDataGridList
            dataSource={OperatingSystemStore}
            header="İşletim Sistemleri"
            editHref="/operating-systems"
        >
            <Column dataField="title" caption="İşletim Sistemi Adı"/>
        </BaseDataGridList>
    )
}
