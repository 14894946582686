import {EnumStore} from "./base/EnumStore";

export const ProductUnitPiece = "PIECE";
export const ProductUnitBox = "BOX";
export const ProductUnitPackage = "PACKAGE";

export const ProductUnitStore = new EnumStore({
        key: "id",
        data: [
            {"id": ProductUnitPiece, "title": "Adet"},
            {"id": ProductUnitBox, "title": "Kutu"},
            {"id": ProductUnitPackage, "title": "Koli"}
        ]
    }
);