import {
    Column,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {VehicleStore} from "../../../datastore/VehicleStore";

export function VehiclesList() {
    return (
        <BaseDataGridList
            dataSource={VehicleStore}
            header="Araçlar"
            editHref="/vehicles"
        >
            <Column dataField="licensePlate" caption="Araç Plakası"/>
        </BaseDataGridList>
    )
}
