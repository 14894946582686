import Form, {Item, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import React, {useEffect, useRef, useState} from "react";
import {PaymentDevice} from "../../../domain/PaymentDevice";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {PaymentDeviceStore} from "../../../datastore/PaymentDeviceStore";
import {isFormValid} from "../../../utils/utils";
import {confirm} from 'devextreme/ui/dialog';
import {PaymentSystemStore} from "../../../datastore/PaymentSystemStore";

export function PaymentDevicesEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const [record, setRecord] = useState<PaymentDevice>();
    const {id} = useParams();
    const isNewRecord = !id;

    useEffect(() => {
        if (!isNewRecord) {
            PaymentDeviceStore.byKey(Number(id)).then((data) => {
                setRecord(data);
            });
        } else {
            setRecord({});
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/payment-devices")
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (isNewRecord) {
            PaymentDeviceStore.insert(record!).then(cancelClick);
        } else {
            PaymentDeviceStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`<b>${record?.chassisCode}</b> şase kodlu ödeme cihazını silmek istiyor musunuz?`, "Ödeme Cihazı Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    PaymentDeviceStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni Ödeme Cihazı" : "Ödeme Cihazı Güncelle"}/>
            <Form ref={formRef}
                  formData={record}
                  onContentReady={(e) => {
                      e.component.getEditor("paymentSystem")?.focus();
                  }}
            >
                <Item dataField="paymentSystem" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: PaymentSystemStore,
                          searchEnabled: true,
                          displayExpr: 'title'
                      }}>
                    <Label text="Ödeme Sistemi"/>
                    <RequiredRule message="Lütfen ödeme sistemi seçiniz"/>
                </Item>
                <SimpleItem dataField="purchaseDate" editorType="dxDateBox" label={{text: "Alış Tarihi"}}
                            editorOptions={{openOnFieldClick: true}}/>
                <SimpleItem dataField="chassisCode" editorType="dxTextBox" label={{text: "Şase Kodu"}}>
                    <RequiredRule message="Lütfen şase kodu giriniz"/>
                </SimpleItem>
                <SimpleItem dataField="mobile" editorType="dxTextBox" label={{text: "Hat No"}} editorOptions={
                    {
                        mask: "(\\0000) 000 00 00",
                        showMaskMode: 'always'
                    }
                }>
                </SimpleItem>
            </Form>
            <Toolbar>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>
        </>
    )
}