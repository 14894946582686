import {Map} from "devextreme-react";
import {useEffect, useState} from "react";
import {CustomerStore} from "../../../datastore/CustomerStore";
import {Customer} from "../../../domain/Customer";
import {Technician} from "../../../domain/Technician";

interface Marker {
    /**
     * A URL pointing to the custom icon to be used for the marker.
     */
    iconSrc?: string;
    /**
     * Specifies the marker location.
     */
    location?: any | string | Array<number>;
    /**
     * A callback function performed when the marker is clicked.
     */
    onClick?: Function;
    /**
     * A tooltip to be used for the marker.
     */
    tooltip?: string | {
        /**
         * Specifies whether a tooltip is visible by default or not.
         */
        isShown?: boolean;
        /**
         * Specifies the text or HTML markup displayed in the tooltip.
         */
        text?: string;
    };

    customer?: Customer,

    technician?: Technician
}

function customerPin(customer: Customer, visited: boolean): Marker {
    return {
        location: customer.address,
        tooltip: {
            text: customer.title,
            isShown: false
        },
        iconSrc: "/img/map/" + (visited ? "green-pin.svg" : "yellow-pin.svg"),
        customer: customer
    }
}

function truckPin(technician: Technician, location: any | string | Array<number>): Marker {
    return {
        location: location,
        tooltip: technician.name,
        iconSrc: "/img/map/truck.svg",
        technician: technician
    }
}

export function MapView() {
    const markerUrl = 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/maps/map-marker.png';
    const [markers, setMarkers] = useState<Array<Marker>>([]);

    useEffect(() => {
        const result: Array<Marker> = [];

        (async () => {
            const customers = await CustomerStore.getTodayVisits();
            result.push(...customers.data
                .map((customer, i): Marker => {
                    return customerPin(customer, i % 2 === 0);
                }));

            const personnelPositions: Array<{
                technician: Technician,
                lat: number,
                lng: number
            }> = [
                {technician: {id: 1, name: 'Ahmet'}, lat: 41.04551010224719, lng: 29.034245810644048},
                {technician: {id: 2, name: 'Orhan'}, lat: 41.058933911535135, lng: 28.964571686141063}
            ]
            result.push(...personnelPositions.map((d) => {
                return truckPin(d.technician, [d.lat, d.lng]);
            }));

            setMarkers(result);
        })();
    }, []);


    return (
        <Map
            apiKey={"AIzaSyADjfR3eqNvBdWm0QkwYTLBonjOSxPubWM"}
            defaultZoom={10}
            height={600}
            width="100%"
            controls={true}
            markerIconSrc={markerUrl}
            markers={markers}
            provider="google">
        </Map>
    )
}