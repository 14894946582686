import Form, {Item, Label, RequiredRule} from "devextreme-react/form";
import Toolbar, {Item as ToolbarItem} from "devextreme-react/toolbar";
import {FormHeaderToolbar} from "../../../components/FormHeaderToolbar";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {isFormValid} from "../../../utils/utils";
import {confirm} from 'devextreme/ui/dialog';
import {TechnicianVehicle} from "../../../domain/TechnicianVehicle";
import {TechnicianVehicleStore} from "../../../datastore/TechnicianVehicleStore";
import {TechnicianStore} from "../../../datastore/TechnicianStore";
import {Technician} from "../../../domain/Technician";
import {VehicleStore} from "../../../datastore/VehicleStore";
import {Vehicle} from "../../../domain/Vehicle";

export function TechnicianVehiclesEdit() {
    const navigate = useNavigate();
    const formRef = useRef<Form>(null);
    const [record, setRecord] = useState<TechnicianVehicle>();
    const {id} = useParams();
    const isNewRecord = !id;

    useEffect(() => {
        if (!isNewRecord) {
            TechnicianVehicleStore.byKey(Number(id)).then((data) => {
                setRecord(data);
            });
        } else {
            setRecord({});
        }
    }, [id, isNewRecord]);

    const cancelClick = () => {
        navigate("/technician-vehicles")
    }

    const saveClick = () => {
        if (!isFormValid(formRef)) return;
        if (isNewRecord) {
            TechnicianVehicleStore.insert(record!).then(cancelClick);
        } else {
            TechnicianVehicleStore.update(Number(id), record!).then(cancelClick);
        }
    }

    const deleteClick = () => {
        confirm(`Teknisyen araç atamasını silmek istiyor musunuz?`, "Teknisyen Araç Ataması Sil")
            .then((dialogResult) => {
                if (dialogResult) {
                    TechnicianVehicleStore.remove(Number(id)).then(() => cancelClick());
                }
            });
    }

    return (
        <>
            <FormHeaderToolbar header={isNewRecord ? "Yeni Teknisyen Araç Ataması" : "Teknisyen Araç Ataması Güncelle"}/>
            <Form ref={formRef}
                  formData={record}
                  onContentReady={(e) => {
                      e.component.getEditor("technician")?.focus();
                  }}
            >
                <Item dataField="technician" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: TechnicianStore,
                          displayExpr: (data: Technician) => {
                              return data ? data.personalCode + " - " + data.name : ""
                          },
                          searchExpr: 'search',
                          searchEnabled: true
                      }}>
                    <Label text="Teknisyen"/>
                    <RequiredRule message="Teknisyen seçiniz"/>
                </Item>

                <Item dataField="vehicle" editorType="dxSelectBox"
                      editorOptions={{
                          dataSource: VehicleStore,
                          displayExpr: (data: Vehicle) => {
                              return data ? data.licensePlate : ""
                          },
                          searchExpr: 'search',
                          searchEnabled: true
                      }}>
                    <Label text="Araç"/>
                    <RequiredRule message="Araç seçiniz"/>
                </Item>
            </Form>
            <Toolbar>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "İptal", icon: "back", onClick: cancelClick}}/>
                <ToolbarItem widget="dxButton" location="before"
                             options={{text: "Kaydet", icon: "save", onClick: saveClick}}/>
                {!isNewRecord &&
                    <ToolbarItem widget="dxButton" location="before"
                                 options={{text: "Sil", icon: "remove", onClick: deleteClick}}/>
                }
            </Toolbar>
        </>
    )
}