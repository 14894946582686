import {BaseStore} from "./base/BaseStore";
import {CustomerVendingVersion} from "../domain/CustomerVendingVersion";
import axios, {AxiosResponse} from "axios";

class CustomerVendingVersionStoreImpl extends BaseStore<CustomerVendingVersion, number> {
    constructor(customerVendingId: number) {
        super(`/api/v1/customer-vendings/${customerVendingId}/versions`);
    }

    activate(id: number): Promise<AxiosResponse<void>> {
        return axios.post(`${this.url}/${id}/activate`);
    }

    clone(id: number): Promise<AxiosResponse<void>> {
        return axios.post(`${this.url}/${id}/clone`);
    }
}

export const CustomerVendingVersionStore = (customerVendingId: number) => new CustomerVendingVersionStoreImpl(customerVendingId);