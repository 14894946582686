import {
    Column, Lookup,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {OrderStore} from "../../../datastore/OrderStore";
import React from "react";
import {OrderStateStore} from "../../../datastore/OrderStateStore";

export function OrdersList() {
    return (
        <BaseDataGridList
            dataSource={OrderStore}
            header="Siparişler"
            editHref="/orders"
            selectedHrefSuffix="/state"
        >
            <Column dataField="id" caption="Sipariş No" width="120"/>
            <Column dataField="invoiceNumber" caption="Fatura No" width="130"/>
            <Column dataField="supplier.title" caption="Tedarikçi"/>
            <Column dataField="orderedAt" caption="Sipariş Tarihi" dataType="date" allowFiltering={false}/>
            <Column dataField="deliveredAt" caption="Teslimat Tarihi & Saati" dataType="datetime" allowFiltering={false}/>
            <Column dataField="totalAmountSum" caption="Toplam Tutar" format=",##0.## TL" width="120"/>
            <Column dataField="state" caption="Durum" width="120">
                <Lookup dataSource={OrderStateStore} valueExpr="id" displayExpr="title"/>
            </Column>
        </BaseDataGridList>
    )
}
