import {
    Column, Lookup,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import React from "react";
import {InventoryTransactionTypeStore} from "../../../datastore/InventoryTransactionTypeStore";
import {InventoryTypeStore} from "../../../datastore/InventoryTypeStore";
import {InventoryTransactionStore} from "../../../datastore/InventoryTransactionStore";

export function InventoryTransactionList() {
    return (
        <BaseDataGridList
            dataSource={InventoryTransactionStore}
            header="Ürün Hareketleri"
            editHref="/inventory-transactions"
        >
            <Column dataField="id" caption="Id" width="100"/>
            <Column dataField="type" caption="Tip" width="120">
                <Lookup dataSource={InventoryTransactionTypeStore} valueExpr="id" displayExpr="title"/>
            </Column>
            <Column dataField="inverseId" caption="Ref Id" width="100"/>

            <Column dataField="fromInventory.id" caption="Kaynak Depo Id" width="130"/>
            <Column dataField="fromInventory.type" caption="Kaynak Depo Tipi" width="130">
                <Lookup dataSource={InventoryTypeStore} valueExpr="id" displayExpr="title"/>
            </Column>
            <Column dataField="fromInventory.title" caption="Kaynak Depo Adı"/>
            <Column dataField="fromInventory.reverseId" caption="Kaynak Depo Ref Id" width="130"/>

            <Column dataField="toInventory.id" caption="Hedef Depo Id" width="130"/>
            <Column dataField="toInventory.type" caption="Hedef Depo Tipi" width="130">
                <Lookup dataSource={InventoryTypeStore} valueExpr="id" displayExpr="title"/>
            </Column>
            <Column dataField="toInventory.title" caption="Hedef Depo Adı"/>
            <Column dataField="toInventory.reverseId" caption="Hedef Depo Ref Id" width="130"/>


        </BaseDataGridList>
    )
}
