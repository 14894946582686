import {
    Column,
} from "devextreme-react/data-grid";
import {BaseDataGridList} from "../../../components/BaseDataGridList";
import {VendingModelStore} from "../../../datastore/VendingModelStore";
import {VendingTypeStore} from "../../../datastore/VendingTypeStore";
import {VendingModel} from "../../../domain/VendingModel";

export function VendingModelsList() {
    return (
        <BaseDataGridList
            dataSource={VendingModelStore}
            header="Otomat Modelleri"
            editHref="/vending-models"
        >
            <Column dataField="title" caption="Otomat Modeli Adı"/>
            <Column dataField="type" caption="Otomat Cinsi"
                    calculateDisplayValue={(data: VendingModel) => VendingTypeStore.findByKey(data?.type)?.title}
                    calculateCellValue={(data: VendingModel) => VendingTypeStore.findByKey(data?.type)?.title}
                    lookup={
                        {
                            dataSource: VendingTypeStore,
                            valueExpr: "id",
                            displayExpr: "title",
                            allowClearing: true
                        }
                    }
            />
        </BaseDataGridList>
    )
}
