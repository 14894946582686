import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {Button, Toolbar} from "devextreme-react";
import React, {useEffect, useState} from "react";
import {Item as ToolbarItem} from "devextreme-react/toolbar";
import Form, {GroupItem, SimpleItem} from "devextreme-react/form";
import {Column as GridColumn, DataGrid} from "devextreme-react/data-grid";
import {CustomerVendingLoad} from "../../../domain/CustomerVendingLoad";
import {CustomerVendingLoadStore} from "../../../datastore/CustomerVendingLoadStore";
import {RowPreparedEvent} from "devextreme/ui/data_grid";
import {CustomerVendingLoadProduct} from "../../../domain/CustomerVendingLoadProduct";
import './customer-vending-load-view.scss';

function rowTemplate(e: RowPreparedEvent<CustomerVendingLoadProduct, number>) {
    if (e.rowType === "header") return;
    if (e.data.demanded > 0) {
        if (e.data.loaded < 1) {
            e.rowElement.classList.add("customer-vending-load-product_demanded-not-loaded");
        } else if (e.data.loaded < e.data.demanded) {
            e.rowElement.classList.add("customer-vending-load-product_demanded-partial-loaded");
        } else {
            e.rowElement.classList.add("customer-vending-load-product_demanded-loaded");
        }
    }
}

export function CustomerVendingLoadsView() {
    const navigate = useNavigate();
    const [record, setRecord] = useState<CustomerVendingLoad>();
    const {id} = useParams();

    useEffect(() => {
        CustomerVendingLoadStore.byKey(Number(id)).then((data) => {
            setRecord(data);
        });
    }, [id]);

    const goBack = () => {
        navigate(`/customer-vending-loads`);
    }

    return (
        <>
            <Toolbar>
                <ToolbarItem location="before">
                    <Button text="OTOMAT DOLUMLARI" onClick={goBack} icon="undo"/>
                </ToolbarItem>
            </Toolbar>


            <Form formData={record} readOnly={true}
            >
                <GroupItem colCount={3}>
                    <SimpleItem dataField="technician.name" editorType="dxTextBox" label={{text: "Teknisyen"}}
                                editorOptions={{readOnly: true}}/>

                    <SimpleItem dataField="startedAt" editorType="dxDateBox"
                                label={{text: "Başlangıç Tarihi & Saati"}}
                                editorOptions={
                                    {
                                        dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
                                        type: 'datetime',
                                        readOnly: true
                                    }
                                }/>

                    <SimpleItem dataField="completedAt" editorType="dxDateBox"
                                label={{text: "Bitiş Tarihi & Saati"}}
                                editorOptions={
                                    {
                                        dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
                                        type: 'datetime',
                                        readOnly: true
                                    }
                                }/>
                </GroupItem>

                <GroupItem caption="Ürünler">
                    <DataGrid
                        dataSource={record?.products}
                        paging={{enabled: false}}
                        showBorders={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        onRowPrepared={e => rowTemplate(e)}
                    >
                        <GridColumn dataField="customerVendingProduct.optionNumber" caption="Sıra" width="80"/>
                        <GridColumn dataField="customerVendingProduct.product.brand.title" caption="Marka Adı"/>
                        <GridColumn dataField="customerVendingProduct.product.title" caption="Ürün Adı"/>
                        <GridColumn dataField="beforeCurrent" caption="Başlangıç" format="#0.##" width="100"/>
                        <GridColumn dataField="missing" caption="Eksik" format="#0.##" width="100"/>
                        <GridColumn dataField="demanded" caption="Talep" format="#0.##" width="100"/>
                        <GridColumn dataField="loaded" caption="Yüklenen" format="#0.##" width="100"/>
                    </DataGrid>
                </GroupItem>
            </Form>
        </>
    )
}