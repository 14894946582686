import Toolbar, {Item} from "devextreme-react/toolbar";

interface Props {
    header: string
}
export function FormHeaderToolbar(props: Props) {
    return (
        <Toolbar>
            <Item location="before">
                <span style={{fontSize: 25, marginLeft: 10}}>{props.header}</span>
            </Item>
        </Toolbar>
    )
}