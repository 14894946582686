import React from 'react';
import './home.scss';
import {MapView} from "../dashboard/map/MapView";
import {VisitedIndicator} from "../dashboard/indicators/VisitedIndicator";
import {DashboardCard} from "../../layouts/dashboard-card/DashboardCard";
import {ProductSaleIndicator} from "../dashboard/indicators/ProductSaleIndicator";
import {SomeCountsIndicator} from "../dashboard/indicators/SomeCountsIndicator";

export default function Home() {
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Vendoline Otomat Hizmetleri</h2>
            <div className={'content-block'}>
                {/*<div className={'dx-card responsive-paddings'}>*/}
                {/*    <div className={'logos-container'}>*/}
                {/*        <img src="https://www.vendoline.com/theme/images/logo.png" alt="logo"/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <DashboardCard title="Bugünkü Ziyaret Haritası & Araç Konumları">
                    <MapView/>
                </DashboardCard>
                <DashboardCard title="Müşteri Ziyaret İstatistikleri" cardStyle={{backgroundColor: '#F6F6EB'}}>
                    <VisitedIndicator/>
                </DashboardCard>
                <DashboardCard title="Satış İstatistikleri" cardStyle={{backgroundColor: '#D7ECD9'}}>
                    <ProductSaleIndicator/>
                </DashboardCard>
                <DashboardCard cardStyle={{backgroundColor: '#F6ECF5'}}>
                    <SomeCountsIndicator/>
                </DashboardCard>

            </div>
        </React.Fragment>
    )
}
