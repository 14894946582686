import axios from "axios";
import CustomStore, {ResolvedData} from "devextreme/data/custom_store";
import {LoadOptions} from "devextreme/data";
import {formatDate} from "devextreme/localization";

interface Sort {
    desc: boolean,
    selector: string | any
}

function normalizeFilterName(name: string) {
    let i = 0;
    let result = '';
    while (i < name.length) {
        if (name[i] === '.') {
            i++;
            result += name[i].toUpperCase();
        } else {
            result += name[i];
        }
        i++;
    }
    return result;
}

function serializeFilterValue(value: any): string {
    if (value == null) return "";
    if (value instanceof Date) return formatDate(value, "yyyy-MM-ddTHH:mm:ss");
    if (value instanceof Array) return value.map((i) => serializeFilterValue(i)).join(",");
    return encodeURI(value);
}

export class BaseStore<
    TItem = any,
    TKey = any,
> extends CustomStore<TItem, TKey> {
    public latestLoadOptions?: LoadOptions;
    public staticFilter: any = {};

    constructor(protected url: string) {
        super({
            load: function (loadOptions: LoadOptions): Promise<ResolvedData<TItem>> {
                const self: any = this;
                return new Promise<ResolvedData<TItem>>((resolve, reject) => {
                    const queryParams: string[] = [];

                    // paging
                    const pageSize = loadOptions.take || 30;
                    const page = Math.ceil(((loadOptions.skip || 0) / pageSize)) + 1;
                    queryParams.push("page=" + page);
                    queryParams.push("size=" + pageSize);
                    if (loadOptions.searchExpr && loadOptions.searchValue) {
                        queryParams.push(loadOptions.searchExpr + "=" + encodeURI(loadOptions.searchValue));
                    }

                    // filtering
                    Object.keys(self.staticFilter).forEach((key) => {
                        queryParams.push(key + "=" + encodeURI(self.staticFilter[key]));
                    });

                    if (loadOptions.filter) {
                        const filter = loadOptions.filter;
                        const filterParams: string[] = [];
                        if (Array.isArray(filter)) {
                            // single filter
                            if (typeof filter[0] == 'string') {
                                filterParams.push(normalizeFilterName(filter[0]) + "=" + serializeFilterValue(filter[2]));
                            } else {
                                let i = 0;
                                while (i < filter.length) {
                                    const filterItem = filter[i];
                                    filterParams.push(normalizeFilterName(filterItem[0]) + "=" + serializeFilterValue(filterItem[2]));
                                    i += 2;
                                }
                            }
                        }
                        if (filterParams.length) queryParams.push(filterParams.join("&"));
                    }

                    // sorting
                    if (loadOptions.sort) {
                        const sortParams: string[] = [];
                        if (Array.isArray(loadOptions.sort)) {
                            loadOptions.sort.forEach((key) => {
                                const s = key as Sort
                                let name = s.selector
                                if (typeof name !== 'string') {
                                    name = s.selector.context.column.dataField
                                }
                                sortParams.push(name + (s.desc ? "-" : ""))
                            });
                        }
                        if (sortParams.length) queryParams.push("sort=" + sortParams.join(","))
                    }

                    const queryString = queryParams.join("&");
                    self.latestLoadOptions = loadOptions;

                    return axios.get<ListResponse<TItem>>(`${url}?${queryString}`)
                        .then(response => {
                            const result = response.data;
                            if (Array.isArray(result)) {
                                return resolve({
                                    data: result.map((item) => self.fetchDataMap(item)),
                                    totalCount: result.length
                                });
                            }
                            return resolve({
                                data: result.data.map((item) => self.fetchDataMap(item)),
                                totalCount: result.totalRecords
                            });
                        });
                });
            },
            byKey: function (key: any): Promise<TItem> {
                const self: any = this;
                return new Promise<TItem>((resolve, reject) => {
                    if (typeof key === "object") {
                        return resolve(key);
                    }
                    return axios.get<TItem>(`${url}/${key}`)
                        .then(response => {
                            return resolve(self.fetchDataMap(response.data));
                        });
                });
            },
            insert: function (values: TItem): Promise<TItem> {
                const self: any = this;
                return new Promise<TItem>((resolve, reject) => {
                    return axios.post<TItem>(`${url}`, values)
                        .then(response => {
                            return resolve(self.fetchDataMap(response.data));
                        });
                });
            },
            update: function (key: TKey, values: TItem): Promise<TItem> {
                const self: any = this;
                return new Promise<TItem>((resolve, reject) => {
                    return axios.put<TItem>(`${url}/${key}`, values)
                        .then(response => {
                            return resolve(self.fetchDataMap(response.data));
                        });
                });
            },
            remove: function (key: TKey): Promise<void> {
                return new Promise<void>((resolve, reject) => {
                    return axios.delete(`${url}/${key}`)
                        .then(() => {
                            return resolve();
                        })
                        .catch((e) => {
                            reject(e);
                        });
                });
            }
        });
    }

    fetchDataMap(item: TItem): any {
        return item;
    }

    addStaticFilter(key: string, value: any) {
        this.staticFilter[key] = value;
    }
}

export interface ListResponse<T> {
    data: T[],
    page: number,
    totalRecords: number,
    totalPages: number
}





